import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
import { Layout, Row, Col, Timeline } from 'antd';
import Navbar from '../../components/layouts/Navbar';
import { Helmet } from "react-helmet";
import { GetAPI } from "../../redux";
import CONFIG from '../../config/env'
import './landing.css';
import { useDispatch } from "react-redux";

const { ImagesUrl } = CONFIG;

var settings = {
    dots: true,
    autoplaySpeed: 2000,
    autoplay: true,
    slidesToShow: 4,
    slidesToScroll: 2,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        }
    ]
};

function Home() {

    const kId = 0;
    const dispatch = useDispatch()
    const [agentLogo, setLogo] = useState([])

    const findAgentLogo = async () => {
        const res = await dispatch(GetAPI({ url: `/api/open/agent/agent_logo` }))
        setLogo(res.payload.data)
    }

    useEffect(() => {
        findAgentLogo()
    }, [])

    return (
        <div style={{ overflowX: 'hidden' }}>
            <Layout>
                <Helmet>
                    <title>KLX | Home</title>
                </Helmet>
                <Navbar skeys={kId} />
                <div className='banner'>
                    <Row style={{
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                        <Col span={8} className='banner-title'>
                            <div style={{ textAlign: 'left', marginLeft: 100 }} className='banner-title'>
                                <h1>DARI BALI UNTUK INDONESIA</h1>
                                <h4>Multi Ekspedisi Solusi Bagi Kirim Dokument dan Barang bagi Masyarakat BALI dan pelaku Eksportir, UKM, Pebisnis Online, dan lain sebagainya yang akan mengirim barangnya.</h4>
                            </div>
                        </Col>
                        <Col span={12}>
                            <img src="/img/others/40.png" className='banner-image' alt="logo" style={{ width: '60%', marginRight: 50 }} />
                        </Col>
                    </Row>
                </div>
            </Layout>
            <hr style={{ maxWidth: '1570px', margin: '0 70px', border: '1px solid rgb(240, 240, 240)' }} />
            <br />
            <Slider {...settings}>
                {agentLogo.map((agl, index) => (
                    <div>
                        <img src={`${ImagesUrl}/${agl.logo}`} width="160" style={{ marginTop: 10 }} />
                    </div>
                ))}
            </Slider>
            <br /> <br /> <br />
            <center style={{ marginBottom: '70px' }}>
                <h1 style={{ color: '#1890ff', fontWeight: 'bold', fontFamily: 'Fredoka' }}>Solusi yang menyeluruh</h1>
            </center> <br />
            <div className="timeline-solusi" style={{ maxWidth: '1700px', marginTop: '40px', padding: '60px 70px', paddingRight: '75px' }}>
                <Timeline>
                    <Timeline.Item data-aos="zoom-in">
                        <Row>
                            <Col lg={6}>
                                <img src="/img/others/solusi_1.png" width="200px" alt="" data-aos="zoom-in" />
                            </Col>
                            <Col lg={6} data-aos="zoom-in-left">
                                <p style={{ color: '#1890ff', fontWeight: 'bold', fontSize: '22px', marginTop: '30px', fontFamily: 'Fredoka' }}>Agent Siap Mengirimkan barang anda.</p>
                                <p style={{ fontSize: '17px' }}>
                                    Dalam Kemudahan pengirim oleh agent terdekat kami.
                                </p>
                            </Col>
                        </Row>
                        <br />
                        <br />
                        <br /><br />
                        <br />
                    </Timeline.Item>
                    <Timeline.Item data-aos="zoom-in">
                        <Row>
                            <Col lg={6}>
                                <img src="/img/others/solusi_2.png" width="200px" alt="" data-aos="zoom-in" />
                            </Col>
                            <Col lg={6} data-aos="zoom-in-left">
                                <p style={{ color: '#1890ff', fontWeight: 'bold', fontSize: '22px', marginTop: '30px', fontFamily: 'Fredoka' }}>Jemput Paket.</p>
                                <p style={{ fontSize: '17px' }}>
                                    Tanpa Beranjak, Kapanpun dan dimanapun kami siap menjemput barang anda.
                                </p>
                            </Col>
                        </Row>
                        <br />
                        <br />
                        <br /><br />
                        <br />
                    </Timeline.Item>
                    <Timeline.Item data-aos="zoom-in">
                        <Row>
                            <Col lg={6}>
                                <img src="/img/others/solusi_3.png" width="200px" alt="" data-aos="zoom-in" />
                            </Col>
                            <Col lg={6} data-aos="zoom-in-left">
                                <p style={{ color: '#1890ff', fontWeight: 'bold', fontSize: '22px', marginTop: '30px', fontFamily: 'Fredoka' }}>Team Support.</p>
                                <p style={{ fontSize: '17px' }}>
                                    Masih bingung atau ada kendala dalam pengiriman. tenang team kami siap membantu anda.
                                </p>
                            </Col>
                        </Row>
                        <br /><br />
                        <br />
                        <br />
                        <br />
                        <br />
                    </Timeline.Item>
                </Timeline>
            </div>
        </div>
    )
}

export default Home