import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GetAPI, PostAPI } from './apiHandling';
const Apps = createSlice({
    name: 'apps',
    initialState: { errorMessage: '', loading: false, shipperCache: [], airlineCache: [], destinationCache: [], agentCache: [], kotaCodeCache: [] },
    reducers: {
        setLoading(state) {
            state.loading = true
        },
        unsetLoading(state) {
            state.loading = false
        },
        seshipper(state, payload) {
            state.shipperCache = payload.payload
        },
        setDestination(state, payload) {
            state.destinationCache = payload.payload;
        },
        setAgent(state, payload) {
            state.agentCache = payload.payload
        },
        setKotaCode(state, payload) {
            state.kotaCodeCache = payload.payload
        },
    }
})

export const { setLoading, unsetLoading, seshipper, setDestination, setAgent, setKotaCode } = Apps.actions
export const loadshipper = createAsyncThunk(
    'apps/load-shipper', async (payload, thunkApi) => {
        const { dispatch } = thunkApi
        const { id } = payload
        const resp = await dispatch(GetAPI({ url: `/api/v1/agents/by/${id}` }))
        if (resp?.payload?.data) dispatch(seshipper(resp.payload.data))
    }
)

export const loadAgent = createAsyncThunk(
    'apps/load-shipper', async (payload, thunkApi) => {
        const { dispatch } = thunkApi
        const resp = await dispatch(GetAPI({ url: `/api/v1/agents/all` }))
        if (resp?.payload?.data) dispatch(setAgent(resp.payload.data))
    }
)

export const loadDestination = createAsyncThunk(
    'apps/load-destination', async (payload, thunkApi) => {
        const { dispatch } = thunkApi
        const resp = await dispatch(GetAPI({ url: `/api/v1/address/all` }))
        if (resp?.payload?.data) dispatch(setDestination(resp.payload.data))
    }
)

export const loadKotaCode = createAsyncThunk(
    'apps/load-kota_code', async (payload, thunkApi) => {
        const { dispatch } = thunkApi
        const resp = await dispatch(GetAPI({ url: `/api/v1/kota_code/all` }))
        if (resp?.payload?.data) dispatch(setKotaCode(resp.payload.data))
    }
)

export default Apps.reducer