import moment from 'moment';
import numeral from 'numeral';
import React, { Fragment, useState } from 'react';
import { blue, grey } from '@ant-design/colors';
import Page from '../../../../../components/pages/Page';
import TablePage from '../../../../../components/pages/TablePage';
import { Modal, Popover, Steps, Tag, Typography } from 'antd';
import { PrintButton } from '../../../../../components/printComp/printResiBooking';
import { useDispatch } from 'react-redux';
import { PostAPI } from '../../../../../redux';

const { Step } = Steps
const { Title, Text } = Typography;

const ModalTracking = ({ open, handleVisible, data }) => {
  const splitStamp = (params) => {
    const textSplit = params.split('#')
    return textSplit[0]
  }
  return (
    <Modal title="Tracking History" footer={null} visible={open} onCancel={handleVisible} width={800}>
      <Steps direction="vertical" current={0.5} progressDot >
        {
          data?.slice(0).reverse().map((item, index) => (
            <Step key={index} title={<Text style={{ color: [index === 0 ? `${blue.primary}` : `${grey[4]}`], fontWeight: [index === 0 ? `bold` : `normal`] }}>{item?.header === undefined ? item?.status : item?.header} - {moment(splitStamp(item.date)).format('DD MMM YYYY, HH:mm')}</Text >}
              description={<span dangerouslySetInnerHTML={{ __html: item.statusweb === undefined ? item.status : item.statusweb }}></span>} />
          ))
        }
      </Steps>
    </Modal>
  )
}

export default ({ breadcrumb }) => {
  const [open, setOpen] = useState(false)
  const [data, setData] = useState(null)
  const dispatch = useDispatch()
  const handleOpen = async (params) => {
    const res = await dispatch(PostAPI({ url: `/api/v1/tracking/all`, data: { btb_number: params } }))
    setOpen(!open)
    setData(res.payload)
  }
  const handleClose = () => setOpen(!open)

  const columns = [
    {
      selector: (row) => (row?.createdAt && moment(row.createdAt).format('DD MMM YYYY HH:mm')) || '-',
      name: "Tanggal",
      id: 'createdAt'
    },
    {
      selector: (row) => row?.btb_number,
      name: "Resi Number",
      id: 'resi_number'
    },
    {
      selector: (row) => row?.trackingByKlx?.status[0].status,
      name: "Tracking",
      id: 'trackingByKlx.status',
      format: (row) => {
        return (
          <Fragment>
            <div style={{ backgroundColor: "#3E79F7", padding: "4px 8px", borderRadius: 8, color: "white", cursor: "pointer" }}
              onClick={() => handleOpen(row.trackingByKlx?.btb_number)}
            >
              View
            </div>
          </Fragment>
        )
      }
    },
    {
      selector: (row) => row?.receiver_name,
      name: "Nama Penerima",
      id: 'receiver_name'
    },
    {
      selector: (row) => row?.receiver_addr,
      name: "Alamat Penerima",
      id: 'receiver_addr'
    },
    {
      selector: (row) => row?.receiver_city.name,
      name: "Kota Tujuan",
      id: 'receiver_city'
    },
    {
      selector: (row) => row?.shipper_name,
      name: "Nama Pengirim",
      id: 'shipper_name'
    },
    {
      selector: (row) => row?.shipper_addr,
      name: "Alamat Pengirim",
      id: 'shipper_addr'
    },
    {
      selector: (row) => row?.goods_desc,
      name: "Jenis Barang",
      id: 'goods_desc'
    },
    {
      selector: (row) => `${row?.pieces} pcs`,
      name: "Pieces",
      id: 'pieces'
    },
    {
      selector: (row) => `${row?.weight} kg`,
      name: "Weight",
      id: 'weight'
    },
    {
      selector: (row) => `${row?.charged_weight} kg`,
      name: "Charges Weight",
      id: 'charged_weight'
    },
    {
      selector: (row) => `Rp. ${numeral(row?.price_agent).format('Rp0,0')}`,
      name: "Ongkos Kirim",
      id: 'price_agent'
    },
  ]

  return (
    <Fragment>
      <Page title='Booking Data' breadcrumb={breadcrumb}>
        <TablePage
          url="api/v1/booking/agent_book"
          actionPosition={'first'}
          createPath={"/acceptence/booking/create"}
          columns={columns}
          otherAction={(row) => {
            return (
              <Fragment>
                {row.status === "V" ?
                  <Popover title={"Reason"} content={`${row.reason}`} trigger="click">
                    <Tag style={{ cursor: "pointer" }} color="red">void</Tag>
                  </Popover>
                  :
                  <PrintButton data={row} />
                }
              </Fragment>
            )
          }}
        />
      </Page>
      <ModalTracking open={open} handleVisible={handleClose} data={data} />
    </Fragment>
  )
}
