import Home from './home';
import Tracking from './tracking';
import BookingPage from './master/booking';

import ConfigPage from './master/config';
import ConfigForm from './master/config/form';

import ShipperPage from './master/shipper';
import ShipperForm from './master/shipper/form';

import PricelistPage from './master/pricelist';
import PricelistForm from './master/pricelist/form';

import CourierPage from './master/courier';
import CourierForm from './master/courier/form';

import AgentPage from './master/agent';
import AgentForm from './master/agent/form';

import AgentUserPage from './master/user_agent';
import AgentUserForm from './master/user_agent/form';

import ConsolidationPage from './consolidation';
import ConsolidationTable from './consolidation/table';

import ReportDaily from './reports/daily'
import ReportMonthly from './reports/monthly'

import User from './master/user';
import UserForm from './master/user/form';
import VoidList from './void_list';

export const Routing = [
    {
        to: '/home',
        level: 0x1ff0,
        component: <Home breadcrumb={['Master', 'Home']} />
    },
    {
        to: '/tracking',
        level: 0x1ff0,
        component: <Tracking breadcrumb={['Acceptance', 'Tracking']} />
    },
    {
        to: '/void_list',
        level: 0x1ff0,
        component: <VoidList breadcrumb={['Acceptance', 'Void']} />
    },
    {
        to: '/master/booking',
        level: 0x1f00,
        component: <BookingPage breadcrumb={['Acceptance', 'Booking']} />
    },

    //--------------config--------------------
    {
        to: '/master/config',
        level: 0x1f00,
        component: <ConfigPage breadcrumb={['Master', 'Config']} />
    },
    {
        to: '/master/config/create',
        level: 0x1f00,
        component: <ConfigForm breadcrumb={['Master', 'Config', 'Create']} />
    },
    {
        to: '/master/config/edit',
        level: 0x1f00,
        component: <ConfigForm breadcrumb={['Master', 'Config', 'Edit']} />
    },

    //--------------shipper--------------------
    {
        to: '/master/shipper',
        level: 0x1f00,
        component: <ShipperPage breadcrumb={['Master', 'Shipper']} />
    },
    {
        to: '/master/shipper/create',
        level: 0x1f00,
        component: <ShipperForm />
    },
    {
        to: '/master/shipper/edit',
        level: 0x1f00,
        component: <ShipperForm />
    },

    //--------------price list--------------------
    {
        to: '/master/pricelist',
        level: 0x1f00,
        component: <PricelistPage breadcrumb={['Master', 'Price List']} />
    },
    {
        to: '/master/pricelist/create',
        level: 0x1f00,
        component: <PricelistForm breadcrumb={['Master', 'Price List', 'Create']} />
    },
    {
        to: '/master/pricelist/edit',
        level: 0x1f00,
        component: <PricelistForm breadcrumb={['Master', 'Price List', 'Edit']} />
    },

    //-------------- courier --------------------
    {
        to: '/master/courier',
        level: 0x1f00,
        component: <CourierPage breadcrumb={['Master', 'Expedisi']} />
    },
    {
        to: '/master/courier/create',
        level: 0x1f00,
        component: <CourierForm breadcrumb={['Master', 'Expedisi', 'Create']} />
    },
    {
        to: '/master/courier/edit',
        level: 0x1f00,
        component: <CourierForm breadcrumb={['Master', 'Expedisi', 'Edit']} />
    },

    //-------------- agent --------------------
    {
        to: '/master/agent',
        level: 0x1f00,
        component: <AgentPage breadcrumb={['Master', 'Agent']} />
    },
    {
        to: '/master/agent/create',
        level: 0x1f00,
        component: <AgentForm breadcrumb={['Master', 'Agent', 'Create']} />
    },
    {
        to: '/master/agent/edit',
        level: 0x1f00,
        component: <AgentForm breadcrumb={['Master', 'Agent', 'Edit']} />
    },

    //-------------- agent user --------------------
    {
        to: '/master/user_agent',
        level: 0x1f00,
        component: <AgentUserPage breadcrumb={['Master', 'User Agent']} />
    },
    {
        to: '/master/user_agent/create',
        level: 0x1f00,
        component: <AgentUserForm breadcrumb={['Master', 'User Agent', 'Create']} />
    },
    {
        to: '/master/user_agent/edit',
        level: 0x1f00,
        component: <AgentUserForm breadcrumb={['Master', 'User Agent', 'Edit']} />
    },
    //-------------- user --------------------

    {
        to: '/master/user',
        level: 0x1fff,
        component: <User breadcrumb={['Master', 'User']} />
    },
    {
        to: '/master/user/create',
        level: 0x1fff,
        component: <UserForm breadcrumb={['Master', 'User', 'Create']} />
    },
    {
        to: '/master/user/edit',
        level: 0x1fff,
        component: <UserForm breadcrumb={['Master', 'User', 'Edit']} />
    },

    //-------------- consolidation --------------------
    {
        to: '/consolidation',
        level: 0x1f00,
        component: <ConsolidationPage breadcrumb={['Acceptance', 'consolidation']} />
    },
    {
        to: '/consolidation/add',
        level: 0x1f00,
        component: <ConsolidationTable breadcrumb={['Acceptance', 'consolidation', "add"]} />
    },

    //------------------report-------------------------
    {
        to: '/report/daily',
        level: 0x1fff,
        component: <ReportDaily breadcrumb={['Report', 'daily']} />
    },
    {
        to: '/report/monthly',
        level: 0x1fff,
        component: <ReportMonthly breadcrumb={['Report', 'monthly']} />
    },

]