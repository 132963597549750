import React from 'react';
import MyModal from '../../../../../components/pages/Modal';
import { Col, Row, Radio } from "antd";
import numeral from "numeral";

export default ({ handleSend, data, setData, visible, handleCancel }) => {

    const onChange = (e) => {
        setData({ ...data, price_expedisi_info: e.target.value });
    };

    return (
        <MyModal
            title="Detail Send Booking"
            visible={visible}
            onOk={data?.price_expedisi_info === undefined ? null : handleSend}
            handleCancel={handleCancel}
            width={800}
        >
            <div>
                <p style={{ color: "red" }}>Pilih sesuai harga yang di inginkan {data?.btb_number}</p>
                <Radio.Group onChange={onChange}>
                    {data?.price_list?.map((child, index) => (
                        <Radio.Button style={{ backgroundColor: [data.price_expedisi_info?.no === index ? "#3E79F7" : ""], color: [data.price_expedisi_info?.no === index ? "white" : ""] }} key={index} value={{ price: child?.Price1st, no: index }}>{child?.Price1st} - {child?.LeadTime} Day</Radio.Button>
                    ))}
                </Radio.Group>
                <br />
                <br />
                <Row justify="space-between">
                    <Col>Number Booking :</Col>
                    <Col>{data?.btb_number}</Col>
                </Row>

                <Row justify="space-between">
                    <Col>Shipper Name :</Col>
                    <Col>{data?.shipper_name}</Col>
                </Row>

                <Row justify="space-between">
                    <Col>Shipper Addr :</Col>
                    <Col>{data.shipper_city?.name}</Col>
                </Row>

                <Row justify="space-between">
                    <Col>Receiver Name :</Col>
                    <Col>{data?.receiver_name}</Col>
                </Row>

                <Row justify="space-between">
                    <Col>Receiver Addr :</Col>
                    <Col>{data.receiver_city?.name}</Col>
                </Row>

                <Row justify="space-between">
                    <Col>Goods Desc :</Col>
                    <Col>{data?.goods_desc}</Col>
                </Row>

                <Row justify="space-between">
                    <Col>Pieces :</Col>
                    <Col>{data?.pieces} Pcs</Col>
                </Row>

                <Row justify="space-between">
                    <Col>Weight :</Col>
                    <Col>{data?.weight} KG</Col>
                </Row>

                <Row justify="space-between">
                    <Col>Charged Weight :</Col>
                    <Col>{data?.charged_weight} KG</Col>
                </Row>

                <Row justify="space-between" style={{ marginTop: 20 }}>
                    <Col><h3>Total </h3></Col>
                    <Col><h5> Rp. {numeral(data.price_expedisi_info?.price * data?.charged_weight).format("0,0.00")} </h5></Col>
                </Row>
            </div>
        </MyModal>
    )
}