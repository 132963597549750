import { notification } from 'antd';
import Swal from 'sweetalert2';

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

export const sendNotification = (notifType, notifMessage) => {
    notification[notifType]({
        duration: 2,
        message: 'Notification',
        description:
            `${notifMessage}`,
    });
}

export const sendNotificationSwal = (notifType, notifMessage) => {
    Toast.fire({
        icon: `${notifType}`,
        title: `${notifMessage}`
    })
}