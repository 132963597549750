import React from 'react';
import { BrowserRouter, Route, Redirect } from "react-router-dom";
import Home from './home/index';
import Tracking from './home/tracking';
import TrackingQr from './home/tracking_qr';
import About from './home/about';
import Dashboard from './dashboard';
import Customer from './customer';
import ShippingRate from './home/shippingRate';

export default () => {
    return (
        <BrowserRouter>
            <Route path="/" exact>
                <Redirect to="/home" />
            </Route>
            <Route path='/home'>
                <Home />
            </Route>
            <Route path='/tracking'>
                <Tracking />
            </Route>
            <Route path='/tracking_qrcode/:id'>
                <TrackingQr />
            </Route>
            <Route path='/about'>
                <About />
            </Route>
            <Route path='/shipping_rate'>
                <ShippingRate />
            </Route>
            <Route path='/dashboard'>
                <Dashboard />
            </Route>
            <Route path='/customer'>
                <Customer />
            </Route>
        </BrowserRouter>
    )
}