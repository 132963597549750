import { Modal } from "antd";
import React from 'react';

export default ({ title, visible, onOk, handleCancel, children, width }) => {
    return (
        <Modal
            title={title} visible={visible} onOk={onOk} onCancel={handleCancel} width={width}
        >
            <div>
                {children}
            </div>
        </Modal>
    )
}