import React from 'react';
import TablePage from '../../../../../components/pages/TablePage';
import Page from '../../../../../components/pages/Page';

import moment from 'moment'
export default ({ breadcrumb }) => {
    const columns = [
        {
            selector: (row) => row?.shipper_name,
            name: "Name",
            id: 'shipper_name'
        },
        {
            selector: (row) => row?.shipper_addr1,
            name: "Address 1",
            id: 'shipper_addr1'
        },
        {
            selector: (row) => row?.shipper_addr2,
            name: "Address 2",
            id: 'shipper_addr2'
        },
        {
            selector: (row) => row?.shipper_addr3,
            name: "Address 3",
            id: 'shipper_addr3'
        },
        {
            selector: (row) => row?.shipper_city,
            name: "City",
            id: 'shipper_city'
        },
        {
            selector: (row) => row?.shipper_zip,
            name: "Zip",
            id: 'shipper_zip'
        },
        {
            selector: (row) => row?.shipper_contact,
            name: "Name Contact",
            id: 'shipper_contact'
        },
        {
            selector: (row) => row?.shipper_phone,
            name: "No Contact",
            id: 'shipper_phone'
        },
        {
            selector: (row) => (row?.createdAt && moment(row.createdAt).format('DD MMM YYYY HH:mm')) || '-',
            name: "CreatedAt",
            id: 'created_at'
        },
    ]
    return (
        <>
            <Page title='Shipper' breadcrumb={breadcrumb} />
            <TablePage
                url="api/v1/shipper"
                actionPosition={'first'}
                createPath="/master/shipper/create"
                editPath={"/master/shipper/edit"}
                columns={columns}
            />
        </>
    )
}