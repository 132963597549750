import { Button, Col, Divider, Row } from "antd";
import { Component, Fragment, useMemo, useRef, useState } from "react";
import { RiPrinterLine } from "react-icons/ri";
import ReactToPrint from 'react-to-print';
import Barcode from 'react-barcode';
import './printStyle.css';
import numeral from "numeral";
import { QRCodeSVG } from 'qrcode.react';
import moment from "moment";
import {
    Charcoder
} from 'charcoder';
import CONFIG from '../../config/env';
const { Url, ImagesUrl } = CONFIG;

class PrintLabelBooking extends Component {
    constructor(props) {
        super(props);
        this.state = { data: props.data };
    }
    render() {
        const data = this.state.data
        const pageStyle = () => {
            return `@page { size: 210mm 297mm}`;
        }
        const hex = new Charcoder('0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ!@#$%^&*()_+{}[]|?/><.,')
        return (
            <div style={{ height: "29.5cm", pageBreakAfter: "always" }}>
                <style>{pageStyle()}</style>
                <div className="content" style={{ display: "flex", flexDirection: "column", color: "black", borderBottom: "2px solid black", borderBottomStyle: "dashed", fontSize: 10, padding: 24, height: "50%" }}>
                    <Row gutter={20} style={{ marginBottom: 20, flex: 2 }}>
                        <Col span={6}>
                            <img src="/img/others/klx_logo.png" width={140} />
                        </Col>
                        <Col span={12} >
                            <Row style={{ alignItems: "center" }}>
                                <Col span={6}>
                                    <img src={`${ImagesUrl}/${data.agent?.logo}`} width="50%" />
                                </Col>
                                <Col span={8}>
                                    <QRCodeSVG value={`http://klx.co.id/tracking_qrcode/${hex.encode(parseInt(data?.btb_number))}`} width={80} />
                                </Col>
                                <Col span={10}>
                                    <Barcode value={data.btb_number} width={1.5} height={40} fontSize={12} />
                                    <p>{moment(new Date()).format('ddd, DD MMM YYYY hh:mm')}</p>
                                </Col>
                            </Row>
                        </Col>
                        <Col className="table-print" span={6} style={{ display: "flex", flexDirection: "column", justifyContent: "end" }}>
                            <div style={{ backgroundColor: "#1A3353", color: "white", padding: 4, textAlign: "center" }}>
                                <h5 style={{ color: "white" }}>Original</h5>
                            </div>
                            <table >
                                <tbody>
                                    <tr>
                                        <td colSpan="2" style={{ textAlign: "center" }}>
                                            <p>Origin</p>
                                            <h5>{data.hub_origin}</h5>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>No Of Pieces</p>
                                            <h5>{data.pieces}</h5>
                                        </td>
                                        <td>
                                            <p>Weight</p>
                                            <h5>{data.charged_weight}kg</h5>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </Col>
                    </Row>

                    <Row gutter={40} style={{ flex: 4 }}>
                        <Col span={8}>
                            <h5 style={{ textAlign: "center", marginBottom: 16 }}>(  Agent/Shipper  )</h5>
                            <Row >
                                <Col span={8}>
                                    <p >Name</p>
                                </Col>
                                <Col span={16}>
                                    <p className="text-title">: {data.shipper_name}</p>
                                </Col>
                                <Col span={8}>
                                    <p>Contact</p>
                                </Col>
                                <Col span={16}>
                                    <p className="text-title">: {data.shipper_phone} / {data.shipper_contact}</p>
                                </Col>
                                <Col span={8}>
                                    <p >Address</p>
                                </Col>
                                <Col span={16}>
                                    <p className="text-title">: {data.shipper_addr.toUpperCase()}</p>
                                    <p className="text-title"> {data.shipper_city.name}</p>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={8}>
                            <h5 style={{ textAlign: "center", marginBottom: 16 }}>(  Cosignee )</h5>
                            <Row >
                                <Col span={8}>
                                    <p >Name</p>
                                </Col>
                                <Col span={16}>
                                    <p className="text-title">: {data.receiver_name}</p>
                                </Col>
                                <Col span={8}>
                                    <p >Contact</p>
                                </Col>
                                <Col span={16}>
                                    <p className="text-title">: {data.receiver_phone} / {data.receiver_contact}</p>
                                </Col>
                                <Col span={8}>
                                    <p >Address</p>
                                </Col>
                                <Col span={16}>
                                    <p className="text-title">: {data.receiver_addr.toUpperCase()}</p>
                                    <p className="text-title"> {data.receiver_city.name}</p>
                                </Col>
                                <Col span={8}>
                                    <p >Good Desc</p>
                                </Col>
                                <Col span={16}>
                                    <p className="text-title">: {data.goods_desc}</p>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={8}>
                            <h5 style={{ textAlign: "center", marginBottom: 8 }}>(  Charged  )</h5>
                            <Row >
                                <Col span={10} >
                                    <p className="text-title"> Freight</p>
                                </Col>
                                <Col span={14} style={{ textAlign: "right" }}>
                                    {!!data.createdByUserAgent.agent_id?.visiblePrice ? <p> IDR {numeral(data.price_agent).format('0,0.00')}</p> : <p>IDR 0.00</p>}
                                </Col>
                                <Col span={10}>
                                    <p className="text-title"> Value Of Good</p>
                                </Col>
                                <Col span={14} style={{ textAlign: "right" }}>
                                    {!!data.createdByUserAgent.agent_id?.visiblePrice ? <p>IDR {numeral(data.goods_value).format('0,0.00')}</p> : <p>IDR 0.00</p>}
                                </Col>
                                <Divider />
                                <Col span={10}>
                                    <h5 className="text-title"> TOTAL</h5>
                                </Col>
                                <Col span={14} style={{ textAlign: "right" }}>
                                    {!!data.createdByUserAgent.agent_id?.visiblePrice ? <h5> IDR {numeral(data.price_agent).format('0,0.00')}</h5> : <h5>IDR 0.00</h5>}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Divider style={{ margin: "8px 0" }} />
                    <Row gutter={40} style={{ flex: 3 }}>
                        <Col span={8}>
                            <Row gutter={60}>
                                <Col span={12}>
                                    <div style={{ marginTop: 60 }}>
                                        <p style={{ borderTop: "2px solid black" }}>Shipper Signature</p>
                                    </div>
                                </Col>
                                <Col span={12}>
                                    <p className="text-bold">Picked Up By </p>
                                    <p>Name: </p>
                                    <p>Date: { }</p>
                                    <p>Time: { }</p>

                                </Col>
                            </Row>
                        </Col>
                        <Col span={8}>
                            <Row gutter={60}>
                                <Col span={12}>
                                    <p className="text-bold">Staf Officer</p>
                                    <div style={{ marginTop: 40 }}>
                                        <p style={{ borderTop: "2px solid black" }}>{data.createdByUserAgent.name}</p>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={8}>
                            <p className="text-bold" style={{ textAlign: "center" }}>Dimention</p>
                            <Row>
                                <Col span={4}><h6>L(cm)</h6></Col>
                                <Col span={4}><h6>W(cm)</h6></Col>
                                <Col span={4}><h6>H(cm)</h6></Col>
                                <Col span={4}><h6>PCS</h6></Col>
                                <Col span={4}><h6>KG</h6></Col>
                                <Col span={4}><h6>VOL</h6></Col>
                            </Row>
                            {data.goods.map((item, idx) => (
                                <Row key={idx}>
                                    <Col span={4}>{item.length}</Col>
                                    <Col span={4}>{item.width}</Col>
                                    <Col span={4}>{item.height}</Col>
                                    <Col span={4}>{item.pieces}</Col>
                                    <Col span={4}>{item.weight}</Col>
                                    <Col span={4}>{item.volume}</Col>
                                </Row>
                            ))}
                        </Col>
                    </Row>
                </div>
                <div className="content" style={{ display: "flex", flexDirection: "column", color: "black", fontSize: 10, padding: 24, height: "50%" }}>
                    <Row gutter={20} style={{ marginBottom: 20, flex: 2 }}>
                        <Col span={6}>
                            <img src="/img/others/klx_logo.png" width={140} />
                        </Col>
                        <Col span={12} >
                            <Row style={{ alignItems: "center" }}>
                                <Col span={6}>
                                    <img src={`${ImagesUrl}/${data.agent?.logo}`} width="50%" />
                                </Col>
                                <Col span={8}>
                                    <QRCodeSVG value={`http://klx.co.id/tracking_qrcode/${hex.encode(parseInt(data?.btb_number))}`} width={80} />
                                </Col>
                                <Col span={10}>
                                    <Barcode value={data.btb_number} width={1.5} height={40} fontSize={12} />
                                    <p>{moment(new Date()).format('ddd, DD MMM YYYY hh:mm')}</p>
                                </Col>
                            </Row>
                        </Col>
                        <Col className="table-print" span={6} style={{ display: "flex", justifyContent: "end" }}>
                            <table >
                                <tbody>
                                    <tr>
                                        <td colSpan="2" style={{ textAlign: "center" }}>
                                            <p>Origin</p>
                                            <h5>{data.hub_origin}</h5>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>No Of Pieces</p>
                                            <h5>{data.pieces}</h5>
                                        </td>
                                        <td>
                                            <p>Weight</p>
                                            <h5>{data.charged_weight}kg</h5>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </Col>
                    </Row>

                    <Row gutter={40} style={{ flex: 4 }}>
                        <Col span={8}>
                            <h5 style={{ textAlign: "center", marginBottom: 16 }}>(  Agent/Shipper  )</h5>
                            <Row >
                                <Col span={8}>
                                    <p >Name</p>
                                </Col>
                                <Col span={16}>
                                    <p className="text-title">: {data.shipper_name}</p>
                                </Col>
                                <Col span={8}>
                                    <p>Contact</p>
                                </Col>
                                <Col span={16}>
                                    <p className="text-title">: {data.shipper_phone} / {data.shipper_contact}</p>
                                </Col>
                                <Col span={8}>
                                    <p >Address</p>
                                </Col>
                                <Col span={16}>
                                    <p className="text-title">: {data.shipper_addr.toUpperCase()}</p>
                                    <p className="text-title"> {data.shipper_city.name}</p>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={8}>
                            <h5 style={{ textAlign: "center", marginBottom: 16 }}>(  Cosignee )</h5>
                            <Row >
                                <Col span={8}>
                                    <p >Name</p>
                                </Col>
                                <Col span={16}>
                                    <p className="text-title">: {data.receiver_name}</p>
                                </Col>
                                <Col span={8}>
                                    <p >Contact</p>
                                </Col>
                                <Col span={16}>
                                    <p className="text-title">: {data.receiver_phone} / {data.receiver_contact}</p>
                                </Col>
                                <Col span={8}>
                                    <p >Address</p>
                                </Col>
                                <Col span={16}>
                                    <p className="text-title">: {data.receiver_addr.toUpperCase()}</p>
                                    <p className="text-title"> {data.receiver_city.name}</p>
                                </Col>
                                <Col span={8}>
                                    <p >Good Desc</p>
                                </Col>
                                <Col span={16}>
                                    <p className="text-title">: {data.goods_desc}</p>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={8}>
                            <h5 style={{ textAlign: "center", marginBottom: 8 }}>(  Charged  )</h5>
                            <Row >
                                <Col span={10} >
                                    <p className="text-title"> Freight</p>
                                </Col>
                                <Col span={14} style={{ textAlign: "right" }}>
                                    {!!data.createdByUserAgent.agent_id?.visiblePrice ? <p> IDR {numeral(data.price_agent).format('0,0.00')}</p> : <p>IDR 0.00</p>}
                                </Col>
                                <Col span={10}>
                                    <p className="text-title"> Value Of Good</p>
                                </Col>
                                <Col span={14} style={{ textAlign: "right" }}>
                                    {!!data.createdByUserAgent.agent_id?.visiblePrice ? <p>IDR {numeral(data.goods_value).format('0,0.00')}</p> : <p>IDR 0.00</p>}
                                </Col>
                                <Divider />
                                <Col span={10}>
                                    <h5 className="text-title"> TOTAL</h5>
                                </Col>
                                <Col span={14} style={{ textAlign: "right" }}>
                                    {!!data.createdByUserAgent.agent_id?.visiblePrice ? <h5> IDR {numeral(data.price_agent).format('0,0.00')}</h5> : <h5>IDR 0.00</h5>}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Divider style={{ margin: "8px 0" }} />
                    <Row gutter={40} style={{ flex: 3 }}>
                        <Col span={8}>
                            <Row gutter={60}>
                                <Col span={12}>
                                    <div style={{ marginTop: 60 }}>
                                        <p style={{ borderTop: "2px solid black" }}>Shipper Signature</p>
                                    </div>
                                </Col>
                                <Col span={12}>
                                    <p className="text-bold">Picked Up By </p>
                                    <p>Name: </p>
                                    <p>Date: { }</p>
                                    <p>Time: { }</p>

                                </Col>
                            </Row>
                        </Col>
                        <Col span={8}>
                            <Row gutter={60}>
                                <Col span={12}>
                                    <p className="text-bold">Staf Officer</p>
                                    <div style={{ marginTop: 40 }}>
                                        <p style={{ borderTop: "2px solid black" }}>{data.createdByUserAgent.name}</p>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={8}>
                            <p className="text-bold" style={{ textAlign: "center" }}>Dimention</p>
                            <Row>
                                <Col span={4}><h6>L(cm)</h6></Col>
                                <Col span={4}><h6>W(cm)</h6></Col>
                                <Col span={4}><h6>H(cm)</h6></Col>
                                <Col span={4}><h6>PCS</h6></Col>
                                <Col span={4}><h6>KG</h6></Col>
                                <Col span={4}><h6>VOL</h6></Col>
                            </Row>
                            {data.goods.map((item, idx) => (
                                <Row key={idx}>
                                    <Col span={4}>{item.length}</Col>
                                    <Col span={4}>{item.width}</Col>
                                    <Col span={4}>{item.height}</Col>
                                    <Col span={4}>{item.pieces}</Col>
                                    <Col span={4}>{item.weight}</Col>
                                    <Col span={4}>{item.volume}</Col>
                                </Row>
                            ))}
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}

export const PrintButton = ({ data, printCopies, title }) => {
    const reff = useRef(null)
    const originalTitle = document.title
    const [copiesPage, setCopiesPage] = useState()

    const onPrintCopies = (n) => {
        let copy = []
        for (let i = 1; i <= n; i++) {
            copy.push(i)
        }
        return setCopiesPage(copy)
    }

    useMemo(() => {
        if (!!printCopies || printCopies > 1) {
            typeof printCopies === 'number' && onPrintCopies(printCopies)
        } else setCopiesPage([1])
    }, [printCopies?.length])

    return (
        <Fragment>
            <ReactToPrint
                trigger={() => <Button title={title} type="link" icon={<RiPrinterLine />} />}
                content={() => reff.current}
                onBeforePrint={() => document.title = `KLX_Booking-${data.btb_number}`}
                onAfterPrint={() => {
                    document.title = originalTitle
                }}
            />
            <div style={{ display: "none" }} >
                <div ref={reff}>
                    {copiesPage?.map(item => (
                        <PrintLabelBooking data={data} key={item} />
                    ))}

                </div>
            </div>
        </Fragment>
    )
}
