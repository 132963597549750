import React from 'react';
import {
    RiHome2Line, RiStore2Line, RiRoadMapLine, RiSettings3Line, RiUserReceived2Line, RiPriceTag3Line, RiTreasureMapLine, RiUserSettingsLine, RiUserStarLine, RiDoorLockBoxLine,
    RiCalendarEventLine, RiCalendarTodoLine, RiUser2Line
} from 'react-icons/ri';
import { Link } from 'react-router-dom';
const SIZE = 20;
export default [
    {
        type: 'group', // Must have
        label: 'Dashboard',
        level: 0x1fff,
        // dashed: true,
        children: [
            {
                key: '/home',
                icon: <RiHome2Line size={SIZE} />,
                label: <Link to={'/home'}>Home</Link>,
                level: 0x1fff
            }
        ]
    },
    {
        type: 'group', // Must have
        label: 'Acceptance',
        level: 0x1ff0,
        children: [
            {
                key: '/master/booking',
                icon: <RiStore2Line size={SIZE} />,
                label: <Link to={'/master/booking'}>List Booking</Link>,
                level: 0x1ff0,
            },
            {
                key: '/tracking',
                icon: <RiRoadMapLine size={SIZE} />,
                label: <Link to={'/tracking'}>Tracking</Link>,
                level: 0x1fff
            },
            {
                key: '/consolidation',
                icon: <RiDoorLockBoxLine size={SIZE} />,
                label: <Link to={'/consolidation'}>Consolidation</Link>,
                level: 0x1fff
            },
            {
                key: '/void_list',
                icon: <RiDoorLockBoxLine size={SIZE} />,
                label: <Link to={'/void_list'}>Void</Link>,
                level: 0x1fff
            }
        ]
    },
    {
        type: 'group', // Must have
        label: 'Master',
        level: 0x1ff0,
        children: [
            {
                key: '/master/config',
                icon: <RiSettings3Line size={SIZE} />,
                level: 0x1ff0,
                label: <Link to={'/master/config'}>Config</Link>,
            },
            {
                key: '/master/pricelist',
                icon: <RiPriceTag3Line size={SIZE} />,
                level: 0x1ff0,
                label: <Link to={'/master/pricelist'}>Pricelist</Link>,
            },
            {
                key: '/master/courier',
                icon: <RiTreasureMapLine size={SIZE} />,
                level: 0x1ff0,
                label: <Link to={'/master/courier'}>Expedition</Link>,
            },
            {
                // key: '/agent',
                icon: <RiUserSettingsLine size={SIZE} />,
                level: 0x1ff0,
                label: "Agents",
                children: [
                    {
                        key: '/master/agent',
                        icon: <RiUserStarLine size={SIZE} />,
                        level: 0x1ff0,
                        label: <Link to={'/master/agent'}>Agent</Link>,
                    },
                    {
                        key: '/master/user_agent',
                        icon: <RiUserReceived2Line size={SIZE} />,
                        level: 0x1ff0,
                        label: <Link to={'/master/user_agent'}>User Agent</Link>,
                    }
                ]
            },
            {
                key: '/master/user',
                icon: <RiUser2Line size={SIZE} />,
                level: 0x1fff,
                label: <Link to={'/master/user'}>User</Link>,
            },
            {
                type: 'group', // Must have
                label: 'Report',
                level: 0x1fff,
                children: [
                    {
                        key: '/report/daily',
                        icon: <RiCalendarEventLine size={SIZE} />,
                        label: <Link to={'/report/daily'}>Daily</Link>,
                        level: 0x1fff
                    },
                    {
                        key: '/report/monthly',
                        icon: <RiCalendarTodoLine size={SIZE} />,
                        label: <Link to={'/report/monthly'}>Monthly</Link>,
                        level: 0x1fff
                    },
                ]
            },
           
        ]
    }
]