import { useEffect, useState } from "react";
import { Button, Col, Form, Input, Layout, Row, Table, Typography } from "antd"
import numeral from "numeral";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import AutoComplete from "../../components/autocomplete/Offline";
import Navbar from "../../components/layouts/Navbar";
import { GetAPI } from "../../redux";
import { sendNotification } from "../../redux/reducer/utils";

const initialValue = {
    weight: "",
    pieces: "",
    height: "",
    length: "",
    width: ""
}

export default function ShippingRate() {
    const origin = "DPS"
    const [form] = Form.useForm();
    const dispatch = useDispatch()
    const [simulationPrice, setSimulationPrice] = useState([])
    const [destinationList, setDestinationList] = useState([])
    const [isValidPrice, setIsValidPrice] = useState(false)
    const [tempDetination, setTempDestination] = useState(null)
    const [priceList, setPriceList] = useState(null)

    const getDestination = async () => {
        try {
            const res = await dispatch(GetAPI({ url: `/api/open/price_lists/destination` }))
            setDestinationList(res.payload.data)

        } catch (error) {
            sendNotification("error", error.message);
        }
    }

    const typePayload = (value) => {
        let result;
        if (value.lenght > 0) {
            result = value
        } else {
            result = [value]
        }
        return result
    }

    const getPrice = async () => {
        try {
            const res = await dispatch(GetAPI({ url: `/api/open/price_lists/price?from=${origin}&to=${tempDetination.destination}` }))
            if (!!res.payload) {
                //variable price Value
                let valPrice = res.payload.data.price
                if (!valPrice) {
                    setIsValidPrice(true)
                    throw new Error("Kota yang dipilih tidak terdaftar!")
                } else {
                    setIsValidPrice(false)
                    const checkResp = typePayload(res.payload.data)
                    setPriceList(checkResp)
                }
            }
        } catch (error) {
            sendNotification("error", error.message);
        }
    }

    const pricing = (array, val) => {
        let result = []
        for (let iii = 0; iii < array.length; iii++) {
            let changePrice = array[iii].price * val
            result.push({ ...array[iii], charged_weight: val, origin_city: origin, price: changePrice })
        }
        return result
    }

    const onSimulation = (value) => {
        const { weight, height, width, length } = value
        let volume = height * width * length / 6000
        volume = Math.ceil(volume)
        let charged_weight = Math.max(weight, volume);
        const result = pricing(priceList, charged_weight)
        setSimulationPrice(result)
    }
    const columns = [
        {
            title: "Origin",
            dataIndex: "origin",
            key: "origin"
        },
        {
            title: "Asal",
            dataIndex: "origin_city",
            key: "origin_city"
        },
        {
            title: "Tujuan",
            dataIndex: "destination",
            key: "destination"
        },
        {
            title: "Berat(KG)",
            dataIndex: "charged_weight",
            key: "charged_weight"
        },
        {
            title: "Ongkos Kirim",
            dataIndex: "price",
            key: "price",
            render: row => numeral(row).format("0,0")
        },

    ]

    useEffect(() => {
        getDestination()
        form.setFieldsValue({ origin })
    }, [])

    useEffect(() => {
        if (!!tempDetination) getPrice()
    }, [tempDetination])

    const kId = 2;

    return (
        <Layout>
            <Helmet>
                <title>KLX | Shipping Rate</title>
            </Helmet>
            <Navbar skeys={kId} />

            <div style={{ height: "100vh", padding: 40, marginTop: 30 }}>
                <Form
                    layout="vertical"
                    form={form}
                    initialValues={initialValue}
                    onFinish={onSimulation}
                >
                    <Typography.Title level={3} style={{ marginTop: 8, marginBottom: 24 }}>Shipping Rate</Typography.Title>
                    <Row gutter={30} align="middle">
                        <Col xs={24} sm={24} lg={12} >
                            <Form.Item label="Origin" name={"origin"}>
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} lg={12}>
                            <Form.Item
                                label="Kota Tujuan"
                                name={"receiver_city"}
                                validateStatus={"error"}
                                {...(!!isValidPrice && { help: `Kota yang di pilih tidak terdaftar! Hubungi Admin KLX` })}
                            >
                                <AutoComplete
                                    data={destinationList}
                                    onChange={(val) => {
                                        setTempDestination(val)
                                    }}
                                    validateStatus={"error"}
                                    value={tempDetination}
                                    getKey={(opt) => opt}
                                    getSelectedOptions={(opt, val) => opt.destination === val.destination}
                                    getValues={(opt) => `${opt.destination}`}

                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} lg={6}>
                            <Form.Item name={"weight"} label="Berat (kg)" rules={[{ required: true }]}>
                                <Input type={"number"} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} lg={6}>
                            <Form.Item name={"height"} label="Tinggi (cm)" rules={[{ required: true }]}>
                                <Input type={"number"} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} lg={6}>
                            <Form.Item name={"width"} label="lebar (cm)" rules={[{ required: true }]}>
                                <Input type={"number"} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} lg={6}>
                            <Form.Item name={"length"} label="panjang (cm)" rules={[{ required: true }]}>
                                <Input type={"number"} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Button disabled={!!isValidPrice || !tempDetination} type="primary" htmlType="submit" block>Search Rate</Button>
                        </Col>
                    </Row>
                </Form>
                {simulationPrice.length > 0 &&
                    <div style={{ marginTop: 30, marginBottom: 60 }}>
                        <h4>Hasil</h4>
                        <Table dataSource={simulationPrice} columns={columns} pagination={false} style={{ marginTop: 30, paddingBottom: 60 }} />
                    </div>
                }
            </div>
        </Layout>

    )
}


