import { Row } from 'antd';
import React, { useEffect, useState } from 'react';
import FormStep from '../../../../../../components/pages/FormStep';
import { useSelector, useDispatch } from 'react-redux';
import { PostAPI } from '../../../../../../redux';
import { sendNotification } from '../../../../../../redux/reducer/utils';

export default (props) => {
  const dispatch = useDispatch()
  const [priceList, setPriceList] = useState({})
  const { shipperCache } = useSelector(state => state.apps);
  const [ins, setIns] = useState(false)

  const getPrice = async () => {
    try {
      const res = await dispatch(PostAPI({ url: `/api/v1/price_lists/price`, data: { destination: props.model.receiver_city } }))
      setPriceList(res.payload.id)
    } catch (error) {
      sendNotification("error", error.message);
    }
  }

  useEffect(() => {
    if (props.model.goods.length === 0) {
      sendNotification("error", "Berat dan dimensi barang tidak boleh kosong!");
      props.setCurrent(props.current - 1)
    } else getPrice()
  }, [props.model.receiver_city])
  return (
    <FormStep
      {...props}
      wrapperCol={{ span: 8 }}
      labelCol={{ span: 5 }}
      url={"/api/v1/booking/agent_booking"}
      callbackPath={"/acceptence/booking"}
      beforeSaveData={(body) => {
        const { asuransi } = body;
        return { ...body, asuransi: ins, };
      }}

    >
      <Row gutter={40}>
      </Row>
    </FormStep>

  )
}
