import React, { Fragment } from 'react';
import TablePage from '../../../../../components/pages/TablePage';
import Page from '../../../../../components/pages/Page';
import moment from 'moment'

export default ({ breadcrumb }) => {
    const columns = [
        {
            selector: (row) => row?.name,
            name: "name",
            id: 'name'
        },
        {
            selector: (row) => row?.city,
            name: "city",
            id: 'city'
        },
        {
            selector: (row) => row?.originCode,
            name: "origin code",
            id: 'originCode'
        },
        {
            selector: (row) => row?.address,
            name: "address",
            id: 'address'
        },
        {
            selector: (row) => row?.postalCode,
            name: "postal code",
            id: 'postalCode'
        },
        {
            selector: (row) => row?.email,
            name: "email",
            id: 'email'
        },
        {
            selector: (row) => row?.phone,
            name: "phone",
            id: 'phone'
        },
        {
            selector: (row) => row?.profit,
            name: "profit",
            id: 'profit'
        },
        {
            selector: (row) => (row?.createdAt && moment(row.createdAt).format('DD-MMM-YYYY HH:mm')) || '-',
            name: "CreatedAt",
            id: 'created_at'
        },
    ]
    return (
        <Fragment>
            <Page title='Agent' breadcrumb={breadcrumb} />
            <TablePage
                title={"Master Agent"}
                url="api/v1/agents"
                actionPosition={'first'}
                createPath="/master/agent/create"
                editPath={"/master/agent/edit"}
                columns={columns}
            />
        </Fragment>
    )
}