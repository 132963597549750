import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {BrowserRouter, Switch, Route, Redirect} from 'react-router-dom'
import LoginPage from '../login';
import DashboardPages from './pages';
import {setApps, initMe} from '../../redux';
import SplashPage from '../../splash';

const UserPage=({userdata})=>{
    return (
        <BrowserRouter basename='/dashboard'>
            <Switch>
                <Route exact path="/">
                    {(!!userdata && <Redirect to={'/home'} /> ) || <Redirect to="/login"/>}
                </Route>
                <Route exact path="/login">
                    {(!!userdata && <Redirect to={'/home'} /> ) || <LoginPage apps={"Dashboard"} />}                    
                </Route>
                <Route path="/*">
                    {(!!userdata && <DashboardPages idx={new Date().getTime()} /> ) || <Redirect to="/login"/>}
                </Route>
            </Switch>
        </BrowserRouter>
    )
}

export default () => {
    const {userdata, initComplete} = useSelector(state=>state.auth)
    const dispatch=useDispatch();
    useEffect(()=>{
        dispatch(setApps('klx-office')); // set applikasi
        dispatch(initMe()); // cek token validasi        
    }, [initMe, setApps])
    if(!initComplete)return <SplashPage />
    return <UserPage userdata={userdata}/>
}