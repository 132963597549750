import React, { Fragment, useMemo, useState } from 'react';
import { Form, Input, Button, Row, Col, Steps, Typography } from 'antd';
import { blue, grey } from '@ant-design/colors';
import { PostAPI } from '../../../../redux';
import { useDispatch } from 'react-redux';
import { RiArrowDownSLine, RiArrowUpSLine, RiCloseCircleLine, RiSearch2Line } from 'react-icons/ri';
import Page from '../../../../components/pages/Page';
import EmptyDataComp from '../../../../components/emptyComp';
import moment from 'moment';
import { sendNotification } from '../../../../redux/reducer/utils';

const { Step } = Steps;
const { Title, Text } = Typography;

export default function Tracking({ breadcrumb }) {
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false);
    const [data, setData] = useState(null)

    const show = () => setOpen(!open)
    const stepShipment = useMemo(() => {
        return data?.miletones
    }, [data])

    const splitStamp = (params) => {
        const textSplit = params.split('#')
        return textSplit[0]
    }

    const onFinish = (values) => {
        dispatch(PostAPI({ url: 'api/v1/tracking/trackingbtb', data: { ...values, courier: 'ajc' }, callbackPath: '/tracking' }))
            .then(resp => {
                if (resp.payload.message === 'Tidak menemukan apapun, harap isi dengan benar!') {
                    setData(null)
                    sendNotification("error", "Nomer AWB tidak terdaftar/tidak Valid")
                } else setData(resp.payload)
            })
    }

    return (
        <Fragment>
            <Page title='Tracking' breadcrumb={breadcrumb} />
            <Title level={3} style={{ textAlign: "center", marginTop: 40 }}>Tracking</Title>
            <Form layout='inline' size='large' onFinish={onFinish} style={{ justifyContent: "center", margin: "32px 0" }} autoComplete="off">
                <Form.Item
                    name={"btb_number"}
                    style={{ width: "60%", marginRight: 0 }}
                    rules={[{ required: true, message: "Pleace input resi number" }]}
                >
                    <Input placeholder='Pleace input resi number ...' allowClear={{ clearIcon: <RiCloseCircleLine onClick={() => setData(null)} /> }} />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit" icon={<RiSearch2Line size={26} />} size="large" />
                </Form.Item>
            </Form>

            <div className='wrapper'>
                {!data ?
                    <EmptyDataComp />
                    :
                    <div className='card-custom'>
                        <div className='head-card' onClick={show} style={{ backgroundColor: `${blue[0]}` }}>
                            <div className='head-item'>
                                <p > Date</p>
                                <p className='text-bold text-sub-title'>{moment(data?.header?.date).format('DD MMM YYYY')} </p>
                            </div>
                            <div className='head-item'>
                                <p > Customer</p>
                                <p className='text-bold text-sub-title'>{data?.header?.shippername}</p>
                            </div>
                            <div className='head-item'>
                                <p > Colly </p>
                                <p className='text-bold text-sub-title'>{data?.header?.colly} Pcs</p>
                            </div>
                            <div className='head-item'>
                                <p > Weight</p>
                                <p className='text-bold text-sub-title'>{data?.header?.weight}</p>
                            </div>
                            <div className='head-item'>
                                {data?.header?.connote === undefined ? null : open ? <RiArrowUpSLine size={24} /> : <RiArrowDownSLine size={24} />}
                            </div>
                        </div>
                        <div className='body-card'>
                            {data?.header?.connote === undefined ? null : !!open && <DetailInfo data={data?.header} />}
                            <h2>Shipment History</h2>
                            <Steps direction="vertical" current={0.5} progressDot >
                                {
                                    stepShipment?.slice(0).reverse().map((item, index) => (
                                        <Step key={index} title={<Text style={{ color: [index === 0 ? `${blue.primary}` : `${grey[4]}`], fontWeight: [index === 0 ? `bold` : `normal`] }}>{item?.header === undefined ? item?.status : item?.header} - {moment(splitStamp(item.date)).format('DD MMM YYYY, HH:mm')}</Text >}
                                            description={<span dangerouslySetInnerHTML={{ __html: item.statusweb === undefined ? item.status : item.statusweb }}></span>} />
                                    ))
                                }
                            </Steps>
                        </div>
                    </div>
                }
            </div>
        </Fragment>
    )
}

const DetailInfo = ({ data }) => {
    return (
        <div className='detail-information'>
            <h2>Detail Information</h2>
            <Row>
                <Col span={12}>
                    <Row>
                        <Col span={7}><p className='text-bold'> Shipper Name:</p></Col>
                        <Col span={16}><p>{data.shippername}</p></Col>
                    </Row>
                    <Row>
                        <Col span={7}><p className='text-bold'> Shipper Address:</p></Col>
                        <Col span={16}><p>{data.shipperaddress}</p></Col>
                    </Row>
                    <Row>
                        <Col span={7}><p className='text-bold'> Shipper City:</p></Col>
                        <Col span={16}><p>{data.shippercity}</p></Col>
                    </Row>
                    <Row>
                        <Col span={7}><p className='text-bold'> Shipper Telp:</p></Col>
                        <Col span={16}><p>{data.shippertelp}</p></Col>
                    </Row>
                    <Row>
                        <Col span={7}><p className='text-bold'> Shipper Contact:</p></Col>
                        <Col span={16}><p>{data.shippercontact}</p></Col>
                    </Row>
                </Col>
                <Col span={12}>
                    <Row>
                        <Col span={7}><p className='text-bold'> Consignee name:</p></Col>
                        <Col span={16}><p>{data.consigneename}</p></Col>
                    </Row>
                    <Row>
                        <Col span={7}><p className='text-bold'> Consignee address:</p></Col>
                        <Col span={16}><p>{data.consigneeaddress}</p></Col>
                    </Row>
                    <Row>
                        <Col span={7}><p className='text-bold'> Consignee city: </p></Col>
                        <Col span={16}><p>{data.consigneecity}</p></Col>
                    </Row>
                    <Row>
                        <Col span={7}><p className='text-bold'> Consignee Telp:</p></Col>
                        <Col span={16}><p>{data.consigneephone}</p></Col>
                    </Row>
                    <Row>
                        <Col span={7}><p className='text-bold'> Consignee Contact:</p></Col>
                        <Col span={16}><p>{data.consigneecontact}</p></Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}