import React, { Fragment } from 'react';
import TablePage from '../../../../../components/pages/TablePage'
import Page from '../../../../../components/pages/Page';
import moment from 'moment'

export default ({ breadcrumb }) => {
    const columns = [
        {
            selector: (row) => row?.courier,
            name: "Expedisi Code",
            id: 'courier'
        },
        {
            selector: (row) => row?.courier_name,
            name: "Name",
            id: 'courier_name'
        },
        {
            selector: (row) => (row?.createdAt && moment(row.createdAt).format('DD-MMM-YYYY HH:mm')) || '-',
            name: "CreatedAt",
            id: 'createdAt'
        },
    ]
    return (
        <Fragment>
            <Page title='Expedisi' breadcrumb={breadcrumb} />
            <TablePage
                title={"Master courier"}
                url="api/v1/courier"
                actionPosition={'first'}
                createPath="/master/courier/create"
                editPath={"/master/courier/edit"}
                columns={columns}
            />
        </Fragment>
    )
}