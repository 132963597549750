import React from 'react'
import { Layout } from 'antd';
import Navbar from '../../components/layouts/Navbar';
import { Helmet } from "react-helmet";
import {
    Charcoder,
    B62
} from 'charcoder';
import TrackingForm from './tracking-form';
import {
    useParams
} from "react-router-dom";
import './landing.css';

function Tracking() {
    const kId = 1;
    let { id } = useParams();

    const hex = new Charcoder('0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ!@#$%^&*()_+{}[]|?/><.,')
    const keychar = (hex.decode(id))

    return (
        <div>
            <Layout>
                <Helmet>
                    <title>KLX | Tracking</title>
                </Helmet>
                <Navbar skeys={kId} />
                <div className='container-tracking'>
                    <TrackingForm id={keychar} />
                </div>
            </Layout>
        </div>
    )
}

export default Tracking