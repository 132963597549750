import React from 'react'

import FormReport from "../../../../../components/pages/FormReport"
import { useFetch } from '../../../../../hooks/useFetch';

export default () => {
  return (
    <FormReport
      title={'Report Daily'}
      repType={'daily'}
      url={"api/v1/report/agent"}
      FetchComponent={useFetch}
    />
  )
}