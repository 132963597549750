import React from 'react';
import LoginForm from './LoginForm';
import { Row, Col } from "antd";
import Page from '../../../components/pages/Page';

const backgroundURL = '/img/others/img-17.jpg'
const backgroundStyle = {
    backgroundImage: `url(${backgroundURL})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
}

export default () => {
    return (
        <div style={{ display: 'flex', flexDirection: 'row', width: '100vw', height: '100vh', justifyContent: 'center', alignItems: 'center', overflow: 'hidden' }}>
            <Page title='Login' />
            <div className='h-100 bg-white'>
                <Row justify="center" className="align-items-stretch h-100">
                    <Col xs={20} sm={20} md={24} lg={16}>
                        <div className="container d-flex flex-column justify-content-center h-100">
                            <Row justify="center">
                                <Col xs={24} sm={24} md={20} lg={12} xl={8}>
                                    <h1>Sign In</h1>
                                    <div className="mt-4">
                                        <LoginForm />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col xs={0} sm={0} md={0} lg={8}>
                        <div className="d-flex flex-column justify-content-between h-100 px-4" style={backgroundStyle}>
                            <div className="text-right">
                                <img src="/img/others/klx_logo.png" alt="logo" style={{ width: 100, margin: 20 }} />
                            </div>
                            <Row justify="center">
                                <Col xs={0} sm={0} md={0} lg={20}>
                                    <img className="img-fluid mb-5" src="/img/others/img-18.png" alt="" />
                                    <h1 className="text-white">Welcome to KLX AGEN</h1>
                                    <p className="text-white">Management of data delivery of goods that are connected directly to the expedition.</p>
                                </Col>
                            </Row>
                            <div className="d-flex justify-content-end p-4">
                                <div>
                                    <a className="text-white" href="https://www.privacypolicies.com/live/f5ea2750-b9d1-4e80-965a-99453872ac78" target="_blank">Term & Conditions</a>
                                    <span className="mx-2 text-white"> | </span>
                                    <a className="text-white" href="https://www.privacypolicies.com/live/f5ea2750-b9d1-4e80-965a-99453872ac78" target="_blank">Privacy & Policy</a>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}
