import React, { useState } from "react";
import { Form, Input, Row, Col } from "antd";
import FormPage from "../../../../../components/pages/FormPage";
import Page from '../../../../../components/pages/Page';

const DefModel = {
    name: "",
    username: "",
    password: "",
    email: "",
    phone: "",
    level: 131056,
    isCreate: true
};

export default ({ breadcrumb }) => {
    const [model, setModel] = useState(DefModel);

    return (
        <FormPage
            url={"api/v1/users"}
            callbackPath={"/master/user"}
            formValid={false}
            model={model}
            setModel={setModel}
            title={"Users"}
        >
            <Page title='User' breadcrumb={breadcrumb} />
            <br />
            <Row gutter={24}>
                <Col span={8}>
                    <Form.Item name={"name"} label="name">
                        <Input className="input-custom-md" size="default" />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item name={"username"} label="username" rules={[{ required: true }]}>
                        <Input className="input-custom-md" size="default" />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item name={"password"} label="password">
                        <Input className="input-custom-md" size="default" />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item name={"email"} label="email" rules={[{ required: true }]}>
                        <Input className="input-custom-md" size="default" />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item name={"phone"} label="phone" rules={[{ required: true }]}>
                        <Input className="input-custom-md" size="default" />
                    </Form.Item>
                </Col>
            </Row>
        </FormPage>
    );
}