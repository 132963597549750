import React from 'react';
import FormReport from "../../../../../components/pages/FormReport"
import { useFetch } from '../../../../../hooks/useFetch';

export default () => {

  return (
    <FormReport
      title={'Report Monthly'}
      repType={'monthly'}
      url={"api/v1/report"}
      FetchComponent={useFetch}
    />
  )
}