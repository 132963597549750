import { Form, Input, Col, Row } from 'antd';
import React, { useMemo, useState } from 'react';
import FormPage from '../../../../../components/pages/FormPage';
import Page from '../../../../../components/pages/Page';
import AutoComplete from '../../../../../components/autocomplete/Offline';
import { useSelector } from 'react-redux';

const DefModel = {
    shipper_name: '',
    shipper_addr1: '',
    shipper_addr2: '',
    shipper_addr3: '',
    shipper_city: '',
    shipper_zip: '',
    shipper_contact: '',
    shipper_phone: '',
}

export default ({ breadcrumb }) => {
    const [model, setModel] = useState(DefModel)
    const { destinationCache } = useSelector(state => state.apps)

    const [qryShipper, setQryShipper] = useState("")
    useMemo(() => {
        let splitCityShipper = model?.shipper_city?.split(",")
        setQryShipper(splitCityShipper[0])
    }, [qryShipper])

    return (
        <div>
            <Page title='shipper' breadcrumb={breadcrumb} />
            <FormPage
                url={'api/v1/shipper'}
                callbackPath={"/master/shipper"}
                formValid={false}
                model={model}
                beforeSaveData={(body) => {
                    const { shipper_city } = body;
                    return { ...body, shipper_city: shipper_city.name };
                }}
                setModel={setModel}
                layout="horizontal"
                wrapperCol={{ span: 8 }}
                labelCol={{ span: 8 }}
                emptyModel={DefModel}
            >
                <Row gutter={40}>
                    <Col span={12}>
                        <Row gutter={60}>
                            <Col span={24}>
                                <Form.Item
                                    label="Name"
                                    name="shipper_name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your name!',
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="Address 1"
                                    name="shipper_addr1"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your address 1!',
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="Address 2"
                                    name="shipper_addr2"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your address 2!',
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="Address 3"
                                    name="shipper_addr3"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your address 3!',
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={12}>
                        <Row gutter={60}>
                            <Col span={24}>
                                <Form.Item
                                    label="City"
                                    name="shipper_city"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your city!',
                                        },
                                    ]}
                                >
                                    <AutoComplete
                                        data={destinationCache}
                                        onChange={(val) => setModel({ ...model, shipper_city: val.name })}
                                        value={model.shipper_city}
                                        getKey={(opt) => opt.name}
                                        getSelectedOptions={(opt, val) => opt.name === val.name}
                                        getValues={(opt) => `${opt.name}`}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="Zip"
                                    name="shipper_zip"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your Zip!',
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="Name Contact"
                                    name="shipper_contact"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your name phone!',
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="No Contact"
                                    name="shipper_phone"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your no phone!',
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </FormPage>
        </div>
    )
}