import { Button, Col, Form, Input, Row, Table, Typography } from "antd";
import numeral from "numeral";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AutoComplete from "../../../../../components/autocomplete/Offline";
import { PostAPI } from "../../../../../redux";
import { sendNotification } from "../../../../../redux/reducer/utils";

const initialValue = {
    weight: "",
    pieces: "",
    height: "",
    length: "",
    width: ""
}

export default () => {
    const dispatch = useDispatch()
    const [form] = Form.useForm();
    const { destinationCache, shipperCache } = useSelector(state => state.apps)
    const [tempDetination, setTempDestination] = useState(null)
    const [isValidPrice, setIsValidPrice] = useState(false)
    const [priceList, setPriceList] = useState(null)
    const [simulationPrice, setSimulationPrice] = useState([])

    const typePayload = (value) => {
        let result;
        if (value.lenght > 0) {
            result = value
        } else {
            result = [value]
        }
        return result
    }

    const getPrice = async () => {
        try {
            const res = await dispatch(PostAPI({ url: `/api/v1/price_lists/price`, data: { destination: tempDetination.name } }))
            if (!!res.payload) {
                let valPrice = res.payload.price
                if (!valPrice) {
                    setIsValidPrice(true)
                    throw new Error("Kota yang dipilih tidak terdaftar!")
                } else {
                    setIsValidPrice(false)
                    const checkResp = typePayload(res.payload)
                    setPriceList(checkResp)
                }
            }
        } catch (error) {
            sendNotification("error", error.message);
        }
    }

    const pricing = (array, val) => {
        let result = []
        for (let iii = 0; iii < array.length; iii++) {
            let changePrice = array[iii].price * val
            result.push({ ...array[iii], charged_weight: val, origin_city: shipperCache.city, price: changePrice })
        }
        return result
    }

    const onSimulation = (value) => {
        const { weight, height, width, length } = value
        let volume = height * width * length / 6000
        volume = Math.ceil(volume)
        let charged_weight = Math.max(weight, volume);
        const result = pricing(priceList, charged_weight)
        setSimulationPrice(result)
    }

    const columns = [
        {
            title: "Origin",
            dataIndex: "origin",
            key: "origin"
        },
        {
            title: "Asal",
            dataIndex: "origin_city",
            key: "origin_city"
        },
        {
            title: "Tujuan",
            dataIndex: "destination",
            key: "destination"
        },
        {
            title: "Berat(KG)",
            dataIndex: "charged_weight",
            key: "charged_weight"
        },
        {
            title: "Ongkos Kirim",
            dataIndex: "price",
            key: "price",
            render: row => numeral(row).format("0,0")
        },

    ]

    useEffect(() => {
        if (!!tempDetination) getPrice()
    }, [tempDetination])

    useEffect(() => {
        form.setFieldsValue({ shipper_city: shipperCache.city })
    }, [shipperCache.city])

    return (
        <Fragment>
            <Form
                layout="vertical"
                form={form}
                initialValues={initialValue}
                onFinish={onSimulation}
            >
                <Typography.Title level={3} style={{ marginTop: 8, marginBottom: 24 }}>Simulation Rate</Typography.Title>
                <Row gutter={30} align="middle">
                    <Col span={12}>
                        <Form.Item label="Kota Asal" name={"shipper_city"}>
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Kota Tujuan"
                            name={"receiver_city"}
                            validateStatus={"error"}
                            {...(!!isValidPrice && { help: `Kota yang di pilih tidak terdaftar! Hubungi Admin KLX` })}
                        >
                            <AutoComplete
                                data={destinationCache}
                                onChange={(val) => {
                                    setTempDestination(val)

                                }}
                                validateStatus={"error"}
                                value={tempDetination}
                                getKey={(opt) => opt}
                                getSelectedOptions={(opt, val) => opt.name === val.name}
                                getValues={(opt) => `${opt.name}`}

                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name={"weight"} label="Berat (kg)" rules={[{ required: true }]}>
                            <Input type={"number"} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name={"height"} label="Tinggi (cm)" rules={[{ required: true }]}>
                            <Input type={"number"} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name={"width"} label="lebar (cm)" rules={[{ required: true }]}>
                            <Input type={"number"} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item name={"length"} label="panjang (cm)" rules={[{ required: true }]}>
                            <Input type={"number"} />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Button disabled={!!isValidPrice || !tempDetination} type="primary" htmlType="submit" block>Create Simulation</Button>
                    </Col>
                </Row>
            </Form>
            {simulationPrice.length > 0 &&
                <div style={{ marginTop: 30 }}>
                    <h4>Hasil</h4>
                    <Table dataSource={simulationPrice} pagination={false} columns={columns} />
                </div>
            }
        </Fragment>

    )
}

