import Home from './pages/home';
import Booking from './pages/acceptence/booking';
import AccptBooking from './pages/acceptence/booking/formBooking';
import CosigneeForm from './pages/acceptence/booking/formBooking/Consignee_form';
import ShipperForm from './pages/acceptence/booking/formBooking/Shipper_form';
import GoodsForm from './pages/acceptence/booking/formBooking/Goods_form';
import MoreForm from './pages/acceptence/booking/formBooking/More_form';
import Profile from './pages/profile';
import ReportDaily from './pages/reports/daily';
import ReportMonthly from './pages/reports/monthly';
import AgentUserPage from './pages/master/user_agent';
import AgentUserForm from './pages/master/user_agent/form';
import Rate from './pages/acceptence/rate';

export const Routing = [
    {
        to: '/home',
        level: 0x1fff,
        component: <Home breadcrumb={['Master', 'Home']} />
    },
    //-------------acceptence-----------------------
    {
        to: '/acceptence/booking',
        level: 0x1fff,
        component: <Booking breadcrumb={['Master', 'Booking']} />
    },

    {
        to: '/acceptence/booking/create',
        level: 0x1fff,
        component: <AccptBooking breadcrumb={['Master', 'Booking']} />
    },
    {
        to: '/acceptence/rate',
        level: 0x1fff,
        component: <Rate breadcrumb={['Master', 'Rate']} />
    },
    //----------------form courier---------------------
    {
        to: '/acceptence/booking/courier/shipper',
        level: 0x1fff,
        component: <ShipperForm breadcrumb={['Booking', 'shipper']} />
    },
    {
        to: '/acceptence/booking/courier/consignee',
        level: 0x1fff,
        component: <CosigneeForm breadcrumb={['Booking', 'consignee']} />
    },
    {
        to: '/acceptence/booking/courier/goods',
        level: 0x1fff,
        component: <GoodsForm breadcrumb={['Booking', 'goods']} />
    },
    {
        to: '/acceptence/booking/courier/more',
        level: 0x1fff,
        component: <MoreForm breadcrumb={['Booking', 'more']} />
    },

    //--------------------profile----------------------
    {
        to: '/profile',
        level: 0x1fff,
        component: <Profile breadcrumb={['Profile']} />
    },

    //------------------report-------------------------
    {
        to: '/report/daily',
        level: 0x1fff,
        component: <ReportDaily breadcrumb={['Report', 'daily']} />
    },
    {
        to: '/report/monthly',
        level: 0x1fff,
        component: <ReportMonthly breadcrumb={['Report', 'monthly']} />
    },

    //-------------- agent user --------------------
    {
        to: '/master/user_agent',
        level: 0x1fff,
        component: <AgentUserPage />
    },
    {
        to: '/master/user_agent/create',
        level: 0x1fff,
        component: <AgentUserForm />
    },
    {
        to: '/master/user_agent/edit',
        level: 0x1fff,
        component: <AgentUserForm />
    },
]