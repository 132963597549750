import { createAsyncThunk } from '@reduxjs/toolkit';
import { setLoading, unsetLoading } from './apps';
import { GetData, PostData } from '../caller';
import { logout } from './auth';
import { sendNotificationSwal } from "./utils";

const doTokenInvalid = dispatch => () => {
    dispatch(logout())
}
export const PostAPI = createAsyncThunk('API/Post', async (payload, thunkApi) => {
    const { dispatch, getState } = thunkApi
    dispatch(setLoading())
    const { auth: { apps, token } } = getState()
    const { url, data } = payload
    let response = false
    try {
        const resp = await PostData(url, data, token, apps, doTokenInvalid(dispatch))
        response = resp.data
    } catch (error) {
        sendNotificationSwal("error", error.message);
    }
    dispatch(unsetLoading())
    return response
})

export const GetAPI = createAsyncThunk('API/Get', async (payload, thunkApi) => {
    const { dispatch, getState } = thunkApi
    dispatch(setLoading())
    const { auth: { apps, token } } = getState()
    const { url } = payload
    let response = false
    try {
        response = await GetData(url, token, apps, doTokenInvalid(dispatch))
    } catch (error) {
        sendNotificationSwal("error", error.message);
    }
    dispatch(unsetLoading())
    return response
})

export const GetAPIMe = createAsyncThunk('API/Get', async (payload, thunkApi) => {
    const { dispatch, getState } = thunkApi
    dispatch(setLoading())
    const { auth: { apps, token } } = getState()
    const { url } = payload
    let response = false
    try {
        response = await GetData(url, token, apps, doTokenInvalid(dispatch))
    } catch (error) {
        // sendNotificationSwal("error", 'sesi berakhir, login kembali');
    }
    dispatch(unsetLoading())
    return response
})