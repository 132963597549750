import React, { Fragment } from 'react';
import TablePage from '../../../../../components/pages/TablePage';
import Page from '../../../../../components/pages/Page';
import moment from 'moment';

export default ({ breadcrumb }) => {
    const columns = [
        {
            selector: (row) => row?.origin,
            name: "Origin",
            id: 'origin'
        },
        {
            selector: (row) => row?.destination,
            name: "Destination",
            id: 'destination'
        },
        {
            selector: (row) => row?.price,
            name: "Price",
            id: 'price'
        },
        {
            selector: (row) => (row?.createdAt && moment(row.createdAt).format('DD-MMM-YYYY HH:mm')) || '-',
            name: "CreatedAt",
            id: 'created_at'
        },
    ]
    return (
        <Fragment>
            <Page title='Price List' breadcrumb={breadcrumb} />
            <TablePage
                title={"Master Price"}
                url="api/v1/price_lists"
                actionPosition={'first'}
                createPath="/master/pricelist/create"
                editPath={"/master/pricelist/edit"}
                columns={columns}
            />
        </Fragment>
    )
}