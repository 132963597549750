import React from 'react';
import TablePage from '../../../../../components/pages/TablePage';
import moment from 'moment';
export default () => {
    const columns = [
        {
            selector: (row) => row?.name,
            name: "name",
            id: 'name'
        },
        {
            selector: (row) => row?.username,
            name: "username",
            id: 'username'
        },
        {
            selector: (row) => row?.email,
            name: "email",
            id: 'email'
        },
        {
            selector: (row) => row?.phone,
            name: "phone",
            id: 'phone'
        },
        {
            selector: (row) => (row?.createdAt && moment(row.createdAt).format('DD-MMM-YYYY HH:mm')) || '-',
            name: "CreatedAt",
            id: 'created_at'
        },
    ]
    return (
        <TablePage
            title={"Master user agent"}
            url="api/v1/agent_users/agent"
            actionPosition={'first'}
            createPath="/master/user_agent/create"
            editPath={"/master/user_agent/edit"}
            columns={columns}
        />
    )
}