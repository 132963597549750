import { Form, Input, Row, Col, Select } from "antd";
import React, { useState } from "react";
import FormPage from "../../../../../components/pages/FormPage";
import Page from '../../../../../components/pages/Page';
import { useSelector } from 'react-redux'
const { Option } = Select;

const DefModel = {
    origin: "",
    destination: "",
    price: "",
    isCreate: true
};

export default ({ breadcrumb }) => {
    const [model, setModel] = useState(DefModel);
    const { destinationCache, kotaCodeCache } = useSelector(state => state.apps)

    return (
        <FormPage
            url={"api/v1/price_lists"}
            callbackPath={"/master/pricelist"}
            formValid={false}
            model={model}
            setModel={setModel}
            title={"pricelist"}
        >
            <Page title='Price List' breadcrumb={breadcrumb} />
            <br />
            <Row gutter={24}>
                <Col span={8}>
                    <Form.Item name={"origin"} label="Origin" rules={[{ required: true }]} >
                        <Select
                            showSearch
                            listItemHeight={3}
                            listHeight={125}
                            style={{ width: '100%' }}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {kotaCodeCache.map((child, index) => (
                                <Option value={child.code}>{child.code}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item name={"destination"} label="Destination">
                        <Select
                            showSearch
                            listItemHeight={3}
                            listHeight={125}
                            style={{ width: '100%' }}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {destinationCache.map((child, index) => (
                                <Option value={child.name}>{child.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item name={"price"} label="Price" rules={[{ required: true }]}>
                        <Input className="input-custom-md" />
                    </Form.Item>
                </Col>
            </Row>
        </FormPage>
    );
}