import { Col, Form, Input, Row } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import FormStep from '../../../../../../components/pages/FormStep';
import AutoComplete from "../../../../../../components/autocomplete/Offline";
import { useDispatch, useSelector } from 'react-redux';
import { PostAPI } from '../../../../../../redux';
import { sendNotification } from '../../../../../../redux/reducer/utils';

//price value
export let valPrice;

const defVal = {
  receiver_city: "",
  receiver_zip: ""
}
export default (props) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm();
  const { destinationCache } = useSelector(state => state.apps)
  const [data, setData] = useState({ ...defVal, ...props.model })
  const [priceId, setPriceId] = useState(null)
  const [isValidPrice, setIsValidPrice] = useState(false)
  const [tempZip, setTempZip] = useState([])
  //load postal code
  useMemo(() => {
    let splitCityReceiver = data?.receiver_city?.name?.split(",")
    const resp = dispatch(PostAPI({ url: `/api/v1/postal_code/sub_district`, data: { sub_district: !!splitCityReceiver && splitCityReceiver[0] } }))
      .then(resp => setTempZip(resp.payload))
  }, [data?.receiver_city,])

  //get price value
  const getPrice = async () => {
    try {
      const res = await dispatch(PostAPI({ url: `/api/v1/price_lists/price`, data: { destination: data.receiver_city?.name } }))
      if (!!res.payload) {

        //variable price Value
        valPrice = res.payload.price
        if (!valPrice) {
          setPriceId(null)
          setIsValidPrice(true)
        }
        else {
          setPriceId(res.payload)
          setIsValidPrice(false)
        }
      } else {
        setPriceId(null)
      }
    } catch (error) {
      sendNotification("error", error.message);
    }
  }

  useMemo(() => {
    if (tempZip.length !== 0) {
      const sorted = tempZip.filter((el) => el.postal_code === props.model.receiver_zip);
      const [selectedZip] = sorted;
      form.setFieldsValue({
        receiver_zip: selectedZip
      })
      setData({ ...data, receiver_zip: selectedZip })
    }

  }, [tempZip])


  useEffect(() => {
    if (data.receiver_city.name) getPrice()
  }, [data.receiver_city])



  return (
    <FormStep
      wrapperCol={{ span: 14 }}
      labelCol={{ span: 7 }}
      form={form}
      {...props}
      beforeSaveData={(body) => {
        const { receiver_city, receiver_zip } = body;
        // checking receiver_zip value
        const optZip = (data) => {
          if (typeof data.receiver_zip === "object") {
            return data.receiver_zip.postal_code
          } else {
            return data.receiver_zip
          }
        }
        return { ...body, receiver_city: data.receiver_city, price_id: priceId?._id, receiver_zip: optZip(data) }
      }}
    >
      <Row>
        <Col span={12}>
          <Form.Item name={"receiver_name"} label="Nama Penerima" rules={[{ required: true, message: "'Nama Penerima' wajib diisi" }]} >
            <Input autoFocus />
          </Form.Item>
          <Form.Item name={"receiver_addr"} label="Alamat Lengkap" rules={[{ required: true, message: "'Alamat Lengkap' wajin diisi" }]} >
            <Input.TextArea />
          </Form.Item>
          <Form.Item
            name={"receiver_city"}
            label="Kota dan Kecamatan"
            validateStatus={"error"}
            rules={[{ required: true, message: "'Kota dan Kecamatan' wajib diisi" }]}
            //checking city 
            {...(!!isValidPrice && { help: `Kota yang di pilih tidak terdaftar! Hubungi Admin KLX` })}
          >
            <AutoComplete
              data={destinationCache}
              onChange={(val) => {
                setData({ ...data, receiver_city: val })

              }}
              value={data.receiver_city}
              getKey={(opt) => opt}
              getSelectedOptions={(opt, val) => opt.name === val.name}
              getValues={(opt) => `${opt.name}`}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={"receiver_zip"} label="Kode Pos" rules={[{ required: true, message: "'Kode Pos' wajib diisi" }]} tooltip="Untuk list cari berdasarkan nama keluarahan">
            {tempZip.length === 0 ? <Input type={"number"} onChange={(e) => setData({ ...data, receiver_zip: e.target.value })} /> : (
              <AutoComplete
                data={tempZip}
                onChange={(val) => setData({ ...data, receiver_zip: val })}
                value={data.receiver_zip}
                getKey={(opt) => opt}
                getSelectedOptions={(opt, val) => opt._id === val._id}
                getValues={(opt) => `${opt.urban} - ${opt.postal_code}`}
              />
            )}
          </Form.Item>
          <Form.Item name={"receiver_contact"} label="Nama Kontak" rules={[{ required: true, message: "'Nama Kontak' wajib diisi" }]} >
            <Input />
          </Form.Item>
          <Form.Item name={"receiver_phone"} label="Nomor Hp" rules={[{ required: true, message: "'Nomor HP' wajib diisi" }]} >
            <Input type={"number"} />
          </Form.Item>
        </Col>
      </Row>
    </FormStep>
  )
}
