import React, { useState } from 'react';
import PagesSteps from '../../../../../../components/pages/PagesSteps';
import Shipper_form from './Shipper_form';
import Consignee_form from './Consignee_form';
import Goods_form from './Goods_form';

export default () => {
  const [model, setModel] = useState({})
  const comp = [
    {
      title: "Data Pengirim",
      compt: (props) => <Shipper_form {...props} model={model} setModel={setModel} />
    },
    {
      title: "Data Penerima",
      compt: (props) => <Consignee_form {...props} model={model} setModel={setModel} />
    },
    {
      title: "Barang",
      compt: (props) => <Goods_form {...props} model={model} setModel={setModel} />
    },
  ]

  return (
    <PagesSteps
      stepCompt={comp}
      title={"Booking"}
      url={"/api/v1/booking/agent_booking"}
      callbackPath={"/acceptence/booking"}
    />
  )
}
