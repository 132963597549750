import React from 'react';
import { Layout } from 'antd';
import Navbar from '../../components/layouts/Navbar';
import { Helmet } from "react-helmet";
import './landing.css';

function About() {
    const kId = 3;
    return (
        <div>
            <Layout>
                <Helmet>
                    <title>KLX | About</title>
                </Helmet>
                <Navbar skeys={kId} />
                <div className='about-banner'>
                    <div style={{ marginTop: 150 }}>
                        <h1>TENTANG KAMI</h1>
                        <p>Khrisnabali International Cargo</p>
                        <p>Jalan Rata Kuta 88R Kuta, KAB. Badung Bali, Bali, Indonesia</p>
                        <p>Phone : 087886818167</p>
                    </div>
                </div>
            </Layout>
        </div>
    )
}

export default About