import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Form, Input, Modal, Row, Table } from 'antd';
import moment from 'moment';
import numeral from 'numeral';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { RiDeleteBin2Fill } from 'react-icons/ri';
import FormStep from '../../../../../../components/pages/FormStep';
import { sendNotification } from '../../../../../../redux/reducer/utils';
import { valPrice } from './Consignee_form';

export default (props) => {
  const [goodsPartial, setGoodsPartial] = useState([])
  const [goodVal, setGoodVal] = useState({})
  const [open, setOpen] = useState(false)
  const handleClose = () => setOpen(!open)

  useMemo(() => {
    if (!!props.model.goods) setGoodsPartial(props.model.goods)
  }, [props.model.goods])

  //  const {goods} = props.model
  const sumVol = goodsPartial.reduce((el, item) => { return parseInt(el) + parseInt(item.volume) }, 0)
  const sumWeight = goodsPartial.reduce((el, item) => { return parseInt(el) + parseInt(item.weight) }, 0)
  const sumPieces = goodsPartial.reduce((el, item) => { return parseInt(el) + parseInt(item.pieces) }, 0)
  const max = Math.max(sumVol, sumWeight)

  //handle save dimention
  const handleSave = (value) => {
    const { height, width, length, pieces, weight } = value
    if (sumWeight + parseInt(weight) > parseInt(goodVal.goods_weight)) {
      return sendNotification("error", "Berat Tidak Sesuai");
    } else if (sumPieces + parseInt(pieces) > parseInt(goodVal.goods_pieces)) {
      return sendNotification("error", "pieces Tidak Sesuai")
    } else {
      let volume = height * width * length / 6000
      volume = Math.ceil(volume)
      const newValue = {
        ...value,
        height: parseInt(height),
        width: parseInt(width),
        length: parseInt(length),
        pieces: parseInt(pieces),
        weight: parseInt(weight),
        volume, id: moment().unix()
      }
      setGoodsPartial([...goodsPartial, newValue])
      setOpen(!open)
    }
  }

  useEffect(() => {
    if (!props.model.price_id) {
      sendNotification("error", "Kota Penerima tidak terdaftar! Hubingi ADMIN KLX")
      props.setCurrent(props.current - 1)
    }
  }, [props.model.receiver_city, props.model?.goods?.length, goodVal.goods_pieces, goodVal.goods_weight])

  //column table dimention
  const column = [
    {
      title: "Wid(cm)",
      dataIndex: "width",
      key: "width"
    },
    {
      title: "Hei(cm)",
      dataIndex: "height",
      key: "height"
    },
    {
      title: "Leng(cm)",
      dataIndex: "length",
      key: "length"
    },
    {
      title: "Wei(kg)",
      dataIndex: "weight",
      key: "weight"
    },
    {
      title: "Pcs",
      dataIndex: "pieces",
      key: "pieces"
    },
    {
      title: "Vol",
      dataIndex: "volume",
      key: "volume"
    },
    {
      title: 'Action',
      dataIndex: 'weight',
      key: 'actions',
      render: (text, record, index) => {
        return (
          <div >
            <Button
              icon={<RiDeleteBin2Fill />}
              onClick={() => {
                const arr = goodsPartial
                arr.splice(index, 1)
                setGoodsPartial([...arr])
              }}
              danger />
          </div>
        )
      }
    },
  ]
  return (
    <FormStep
      {...props}
      wrapperCol={{ span: 12 }}
      labelCol={{ span: 12 }}
      beforeSaveData={(body) => {
        const { goods, goods_value } = body;
        return { ...body, goods_weight: goodVal.goods_weight, goods_pieces: goodVal.goods_pieces, goods: goodsPartial, goods_value: goods_value || 0 }
      }}
      //validationValue before finish = mush return boolean
      validationValue={({ data }) => {
        const { goods_weight, goods_pieces } = data
        if (data.goods?.length !== 0) {
          if (parseInt(goods_weight) !== sumWeight) {
            sendNotification("error", "Berat  barang tidak sesuai");
            return false
          } else if (parseInt(goods_pieces) !== sumPieces) {
            sendNotification("error", "Pieces  barang tidak sesuai");
            return false
          }
        }
        return true
      }}
      //modal component preview after finish
      compPreview={({ model }) => {
        const columnPreview = [
          {
            title: "W(cm)",
            dataIndex: "width",
            key: "width"
          },
          {
            title: "H(cm)",
            dataIndex: "height",
            key: "height"
          },
          {
            title: "L(cm)",
            dataIndex: "length",
            key: "length"
          },
          {
            title: "W(kg)",
            dataIndex: "weight",
            key: "weight"
          },
          {
            title: "Pcs",
            dataIndex: "pieces",
            key: "pieces"
          },
          {
            title: "Vol",
            dataIndex: "volume",
            key: "volume"
          },
        ]
        return (
          <Fragment>
            <h2>Informasi Pengiriman</h2>
            <Row gutter={40}>
              <Col span={12}>
                <Row>
                  <Col span={8}><p className='text-bold'> Shipper Name</p></Col>
                  <Col span={16}><p> {model.shipper_name}</p></Col>
                </Row>
                <Row>
                  <Col span={8}><p className='text-bold'> Shipper Address</p></Col>
                  <Col span={16}><p> {model.shipper_addr}</p></Col>
                </Row>
                <Row>
                  <Col span={8}><p className='text-bold'> Shipper City</p></Col>
                  <Col span={16}><p> {model.shipper_city.name}</p></Col>
                </Row>
                <Row>
                  <Col span={8}><p className='text-bold'> Shipper Telp</p></Col>
                  <Col span={16}><p> {model.shipper_phone}</p></Col>
                </Row>
                <Row>
                  <Col span={8}><p className='text-bold'> Shipper Contact</p></Col>
                  <Col span={16}><p> {model.shipper_contact}</p></Col>
                </Row>
                <Row>
                  <Col span={8}><p className='text-bold'> Shipper Postal Code</p></Col>
                  <Col span={16}><p> {model.shipper_zip}</p></Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row>
                  <Col span={8}><p className='text-bold'> Consignee name</p></Col>
                  <Col span={16}><p> {model.receiver_name}</p></Col>
                </Row>
                <Row>
                  <Col span={8}><p className='text-bold'> Consignee address</p></Col>
                  <Col span={16}><p> {model.receiver_addr}</p></Col>
                </Row>
                <Row>
                  <Col span={8}><p className='text-bold'> Consignee city </p></Col>
                  <Col span={16}><p> {model.receiver_city.name}</p></Col>
                </Row>
                <Row>
                  <Col span={8}><p className='text-bold'> Consignee Telp</p></Col>
                  <Col span={16}><p> {model.receiver_phone}</p></Col>
                </Row>
                <Row>
                  <Col span={8}><p className='text-bold'> Consignee Contact</p></Col>
                  <Col span={16}><p> {model.receiver_contact}</p></Col>
                </Row>
                <Row>
                  <Col span={8}><p className='text-bold'> Consignee Postal Code</p></Col>
                  <Col span={16}><p> {model.receiver_zip}</p></Col>
                </Row>
              </Col>
            </Row>
            <Divider />
            <h2>Detail Barang</h2>
            <Row gutter={20}>
              <Col span={14}>
                <Table dataSource={model.goods} columns={columnPreview} style={{ width: "100%" }} pagination={false} rowKey={(record) => record.id} />
              </Col>
              <Col span={10}>
                <Row>
                  <Col span={12}>
                    <p className='text-bold'>Jenis Barang  </p>
                    <p className='text-bold'>Nilai Barang  </p>
                    <p className='text-bold'>Tot. Berat  </p>
                    <p className='text-bold'>Tot. Volume  </p>
                    <p className='text-bold'>Tot. Piecies  </p>
                  </Col>
                  <Col span={12} style={{ textAlign: "right" }}>
                    <p>{model.goods_desc}</p>
                    <p>Rp. {numeral(model.goods_value).format('0,0')}</p>
                    <p>{model.goods_weight || sumWeight} KG</p>
                    <p>{sumVol} </p>
                    <p>{model.goods_pieces || sumPieces} PCS</p>
                  </Col>
                </Row>
                <div style={{ fontSize: 24, fontWeight: "bold", borderTop: "1px solid #1A3353", display: "flex", justifyContent: "space-between" }}>
                  <p>Total Bayar</p>
                  <p style={{ textAlign: "right" }}>Rp. {model.goods?.length === 0 ? numeral(valPrice * parseInt(model.goods_weight)).format('0,0') : numeral(valPrice * max).format('0,0')}</p>
                </div>
              </Col>
            </Row>

          </Fragment>
        )
      }}

    >
      <Row gutter={40}>
        <Col span={10}>
          <Form.Item name={"goods_desc"} label="Jenis Barang" rules={[{ required: true, message: "'Jenis Barang' wajib diisi" }]} >
            <Input autoFocus />
          </Form.Item>
          <Form.Item name={"goods_value"} label="Nilai Barang"  >
            <Input type={"number"} />
          </Form.Item>
          <Form.Item name={"goods_weight"} label="Berat Keseluruhan" rules={[{ required: true, message: "'Berat Keseluruhan' wajib diisi" }]} >
            <Input type={"number"} onChange={(e) => setGoodVal({ ...goodVal, goods_weight: e.target.value })} />
          </Form.Item>
          <Form.Item name={"goods_pieces"} label="Jumlah Keseluruhan" rules={[{ required: true, message: "'Jumlah Barang Keseluruhan' wajib diisi" }]} >
            <Input type={"number"} onChange={(e) => setGoodVal({ ...goodVal, goods_pieces: e.target.value })} />
          </Form.Item>
        </Col>
        <Col span={14}>
          <h3>Goods Details</h3>
          <div style={{ display: "flex", justifyContent: "end" }}>
            {
              sumWeight < goodVal.goods_weight || sumPieces < goodVal.goods_pieces ? (
                <Button type="primary" size="small" icon={<PlusOutlined />} onClick={() => setOpen(!open)} >Add </Button>
              ) : ("")
            }
          </div>
          <Table dataSource={goodsPartial} columns={column} style={{ width: "100%", padding: "24px 0px" }} pagination={false} rowKey={(record) => record.id} />
        </Col>
      </Row>
      {open && (<ModalGoods open={open} goodsPartial={goodsPartial} handleSave={handleSave} handleClose={handleClose} />)}
    </FormStep>
  )
}

//modal dimention
const ModalGoods = ({ open, goodsPartial, handleSave, handleClose }) => {
  return (
    <Modal title={"Dimention dan Weight Good"} visible={open} footer={null} onCancel={handleClose}>
      <Form
        layout='vertical'
        initialValues={goodsPartial}
        onFinish={handleSave}
      >
        <Row gutter={30}>
          <Col span={8}>
            <Form.Item name={"height"} label="Tinggi (cm)" rules={[{ required: true }]}>
              <Input autoFocus type={"number"} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name={"width"} label="lebar (cm)" rules={[{ required: true }]}>
              <Input type={"number"} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name={"length"} label="panjang (cm)" rules={[{ required: true }]}>
              <Input type={"number"} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={"weight"} label="Berat (kg)" rules={[{ required: true }]}>
              <Input type={"number"} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={"pieces"} label="Pieces (pcs)" rules={[{ required: true }]}>
              <Input type={"number"} />
            </Form.Item>
          </Col>
        </Row>
        <div style={{ textAlign: "right" }}>
          <Button htmlType='submit' type='primary' >save</Button>
        </div>
      </Form>
    </Modal>
  )
}
