import React, { Fragment, useState } from "react";
import TablePage from '../../../../../components/pages/TablePage';
import Page from '../../../../../components/pages/Page';
import CourierModal from './caurier_selected';
import DetailModal from './detail_booking';
import { PostAPI } from "../../../../../redux";
import { SendOutlined } from "@ant-design/icons";
import { blue, red } from "@ant-design/colors";
import { Button, Popover, Tag } from "antd";
import numeral from "numeral";
import moment from 'moment';
import { useDispatch } from "react-redux";
import Swal from 'sweetalert2';
import { PrintButton } from "../../../../../components/printComp/printResiBooking";
import { Print_label } from "../../../../../components/printComp/print_label";
import { RiFileMarkLine } from "react-icons/ri";
import { MdInfoOutline } from "react-icons/md";
import VoidModal from './void'

export default ({ breadcrumb }) => {
    const dispatch = useDispatch();
    const [openCourier, setOpenCourier] = useState(false)
    const [openDetail, setOpenDetail] = useState(false)
    const [openVoid, setOpenVoid] = useState(false)
    const [voidData, setVoidData] = useState({})
    const [data, setData] = useState({})
    const [refresh, setRefresh] = useState(moment().unix());

    const columns = [
        {
            selector: (row) => row?.awb_number || "-",
            name: "AWB",
            id: 'awb_number'
        },
        {
            selector: (row) => row?.resi_number || "-",
            name: "HAWB",
            id: 'resi_number'
        },
        {
            selector: (row) => row?.agent.name,
            name: "Agent Booking",
            width: 200,
            id: 'agent'
        },
        {
            selector: (row) => row?.courier || "-",
            name: "Expedisi",
            id: 'courier'
        },
        {
            selector: (row) => row?.btb_number,
            name: "Resi Number",
            id: 'btb_number'
        },
        {
            selector: (row) => row?.shipper_name,
            name: "Shipper Name",
            id: 'shipper_name'
        },
        {
            selector: (row) => row?.shipper_addr,
            name: "Shipper Addr",
            id: 'shipper_addr'
        },
        {
            selector: (row) => row?.shipper_phone,
            name: "Shipper Phone",
            id: 'shipper_phone'
        },
        {
            selector: (row) => row?.receiver_name,
            name: "Receiver Name",
            id: 'receiver_name'
        },
        {
            selector: (row) => row?.receiver_addr,
            name: "Receiver Addr",
            id: 'receiver_addr'
        },
        {
            selector: (row) => row?.receiver_phone,
            name: "Receiver Phone",
            id: 'receiver_phone'
        },
        {
            selector: (row) => `${row?.pieces} pcs`,
            name: "Pieces",
            id: 'pieces'
        },
        {
            selector: (row) => `${row?.weight} kg`,
            name: "Weight",
            id: 'weight'
        },
        {
            selector: (row) => `${row?.charged_weight} kg`,
            name: "Charges Weight",
            id: 'charged_weight'
        },
        {
            selector: (row) => `Rp. ${numeral(row?.price_agent).format("0,0.00")}`,
            name: "Price Agent",
            id: 'price_agent'
        },
        {
            selector: (row) => `Rp. ${numeral(row?.price_klx).format("0,0.00")}`,
            name: "Price KLX",
            id: 'price_klx'
        },
        {
            selector: (row) => `Rp. ${numeral(row?.price_courier).format("0,0.00")}` || "-",
            name: "Price Expedisi",
            id: 'price_courier'
        },
        {
            selector: (row) => `Rp. ${numeral(row?.profit_klx).format("0,0.00")}` || "-",
            name: "Profit KLX",
            id: 'profit_klx'
        },
        {
            selector: (row) => `${(row?.createdAt && moment(row.createdAt).format('DD MMM YYYY HH:mm'))}` || '-',
            name: "CreatedAt",
            id: 'createdAt'
        },
    ]

    //void modal
    const handleModalVoid = (row) => {
        setVoidData(row)
        setOpenVoid(!openVoid)
    }

    // courier modal
    const handleOpenCourier = () => {
        setOpenCourier(true)
    }
    const handleOkCourier = () => {
        setOpenCourier(false);
    };
    const handleCloseCourier = () => {
        setOpenCourier(false)
    }

    // details modal
    const handleCloseDetail = () => {
        setOpenDetail(false)
    }

    const handleOpenDetail = (row) => {
        const { hub_origin, receiver_city, courier, service } = row;

        const checkPrice = {
            OriginCabang: hub_origin,
            Destination: receiver_city.name,
            Service: service,
            submit: "Send",
            courier: courier
        }

        dispatch(PostAPI({ url: `api/v1/price`, data: checkPrice })).then(resp => {
            setData({ ...row, price_list: resp.payload.list, courier: courier })
        });
        setOpenDetail(!openDetail)
    }

    const handleSend = () => {
        dispatch(PostAPI({ url: `api/v1/booking/courier_booking`, data })).then(resp => {
            if (resp.payload) {
                setRefresh(moment().unix());
                handleCloseDetail()
                setData({})
                Swal.fire(
                    'Good job!',
                    'Success Send Expedisi!',
                    'success'
                )
            }
        });
    }

    const handleVoid = (row) => {
        dispatch(PostAPI({ url: `api/v1/booking/void`, data: { ...voidData, ...row } }));
        setRefresh(moment().unix());
        setOpenVoid(!openVoid)
    }

    return (
        <Fragment>
            <Page title='Booking Data' breadcrumb={breadcrumb} />
            <TablePage
                url="api/v1/booking/book_all"
                refresh={refresh}
                actionPosition={'first'}
                columns={columns}
                otherAction={(row, index) => {
                    return (
                        <div>
                            {row.status === "V" ?
                                <Popover title={"Reason"} content={`${row.reason}`} trigger="click">
                                    <Tag style={{ cursor: "pointer" }} color="red">void</Tag>
                                </Popover> :
                                <Fragment>
                                    <PrintButton title="Print Resi" data={row} printCopies={2} />
                                    {row.status === "O" &&
                                        <Fragment>
                                            <Button
                                                title="Send to Expedisi"
                                                style={{ color: blue[4] }}
                                                icon={<SendOutlined color={blue[4]} />}
                                                hidden={row.status === "O" ? false : true}
                                                shape="circle"
                                                type="link"
                                                onClick={() => {
                                                    handleOpenCourier()
                                                    setData(row)
                                                }}
                                            />

                                            <Button
                                                title="Void"
                                                style={{ color: blue[4] }}
                                                icon={<MdInfoOutline color={red[4]} />}
                                                hidden={row.status === "O" ? false : true}
                                                shape="circle"
                                                type="link"
                                                onClick={() => handleModalVoid(row)}
                                            />
                                        </Fragment>

                                    }
                                    {row.status !== "O" && (
                                        <Button
                                            title="Print Label"
                                            style={{ color: blue[4] }}
                                            icon={<RiFileMarkLine color={blue[4]} />}
                                            shape="circle"
                                            type="link"
                                            onClick={() => {
                                                Print_label(row)
                                            }}
                                        />
                                    )}
                                </Fragment>
                            }
                        </div>
                    )
                }}
            />
            <CourierModal data={data} setData={setData} visible={openCourier} handleOpenDetail={handleOpenDetail} onOk={handleOkCourier} handleCancel={handleCloseCourier} />
            <DetailModal handleSend={handleSend} data={data} setData={setData} visible={openDetail} handleCancel={handleCloseDetail} />
            {<VoidModal visible={openVoid} handleCancel={handleModalVoid} handleVoid={handleVoid} voidData={voidData} setVoidData={setVoidData} />}
        </Fragment>
    )
}