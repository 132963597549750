import React, { useState } from "react";
import { Form, Input, Row, Col, InputNumber, Select, Upload, Button, Radio } from "antd";
import FormPage from "../../../../../components/pages/FormPage";
import Page from '../../../../../components/pages/Page';
import { useSelector } from 'react-redux';
import { UploadOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import CONFIG from '../../../../../config/env'

const { ImagesUrl } = CONFIG;
const { Option } = Select;
const DefModel = {
    name: "",
    city: "",
    originCode: "",
    address: "",
    postalCode: "",
    email: "",
    phone: "",
    profit: "",
    logo: "",
    visiblePrice: "",
    isCreate: true
};

export default ({ breadcrumb }) => {

    const [model, setModel] = useState(DefModel);
    const { destinationCache, kotaCodeCache } = useSelector(state => state.apps)

    const props1 = {
        name: 'file',
        beforeUpload: (file) => {
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png';
            if (!isJpgOrPng) {
                toast.error('File tidak mendukung')
                return Upload.LIST_IGNORE
            }
            const isLt2M = file.size / 1024 / 1024 < 3;
            if (!isLt2M) {
                toast.error('Gambar harus lebih kecil dari 3MB')
                return Upload.LIST_IGNORE
            }
            return false;
        }
    };

    const beforeSaveData = (body) => {
        const file = body.logo.file
        const fm = new FormData;
        fm.append('pict', file)
        fm.append('body', JSON.stringify({ ...body }));
        return fm;
    }

    const handleSelect = (value) => {
        setModel({ ...model, PriceType: value })
    }

    return (
        <FormPage
            url={"api/v1/agents"}
            callbackPath={"/master/agent"}
            formValid={false}
            beforeSaveData={beforeSaveData}
            model={model}
            setModel={setModel}
            title={"pricelist"}
        >
            <Page title='Agent' breadcrumb={breadcrumb} />
            <br />
            <Row gutter={24}>
                <Col span={6}>
                    <Form.Item name={"name"} label="Name" rules={[{ required: true }]} >
                        <Input autoFocus className="input-custom-md" size="default" />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item name={"city"} label="City" rules={[{ required: true }]}>
                        <Select
                            showSearch
                            listItemHeight={3}
                            listHeight={125}
                            style={{ width: '100%' }}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {destinationCache.map((kab, index) => (
                                <Option value={kab.name} key={index}>{kab.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item name={"originCode"} label="City Code" rules={[{ required: true }]} tooltip="example denpasar code city DPS">
                        <Select
                            showSearch
                            listItemHeight={3}
                            listHeight={125}
                            style={{ width: '100%' }}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {kotaCodeCache.map((child, index) => (
                                <Option value={child.code} key={index}>{child.code}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item name={"address"} label="Address" rules={[{ required: true }]}>
                        <Input className="input-custom-md" size="default" />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item name={"postalCode"} label="Postal Code" rules={[{ required: true }]} >
                        <InputNumber className="input-custom-md" size="default" style={{
                            width: '100%',
                        }} />
                    </Form.Item>
                </Col>

                <Col span={6}>
                    <Form.Item name={"email"} label="Email" rules={[{ required: true }]}>
                        <Input className="input-custom-md" size="default" />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item name={"phone"} label="Phone" rules={[{ required: true }]}>
                        <InputNumber className="input-custom-md" size="default" style={{
                            width: '100%',
                        }} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item name={"profit"} label="Profit" rules={[{ required: true }]} tooltip="Persentase profit agent">
                        <InputNumber className="input-custom-md" size="default" style={{
                            width: '100%',
                        }} />
                    </Form.Item>
                </Col>

                <Col span={6}>
                    <Form.Item name={"logo"} label="logo" rules={[{ required: true }]} tooltip="upload logo agent">
                        <Upload

                            maxCount={1}
                            accept=".png, .jpg, .jpeg"
                            {...props1}
                        >
                            <Button icon={<UploadOutlined />}>Upload</Button>
                        </Upload>
                    </Form.Item>
                    {model.logo && <img src={`${ImagesUrl}/${model.logo}`} width="50%" />}
                </Col>
                <Col span={6}>
                    <Form.Item label="Visible Price" name={"visiblePrice"} rules={[{ required: true }]} tooltip="To visible Price in Resi">
                        <Radio.Group onChange={handleSelect} defaultValue={false}>
                            <Radio value={true}>Yes</Radio>
                            <Radio value={false}>No</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>
            </Row>
        </FormPage>
    );
}