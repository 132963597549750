import { Form, Input, Row, Col } from "antd";
import React, { useState } from "react";
import FormPage from "../../../../../components/pages/FormPage";
import Page from '../../../../../components/pages/Page';

const DefModel = {
    name: "",
    value: "",
    type: "string",
    field: "",
    isCreate: true,
};

export default ({ breadcrumb }) => {
    const [model, setModel] = useState(DefModel);
    return (
        <FormPage
            url={"api/v1/config"}
            callbackPath={"/master/config"}
            formValid={false}
            model={model}
            setModel={setModel}
            title={"Config"}
        >
            <Page title='Config' breadcrumb={breadcrumb} />
            <br />
            <Row gutter={24}>
                <Col className="gutter-row" span={8}>
                    <Form.Item name={"name"} label="name" rules={[{ required: true }]} >
                        <Input autoFocus size="middle" className="input-custom-md" />
                    </Form.Item>
                    <Form.Item name={"value"} label="value" rules={[{ required: true }]} >
                        <Input autoFocus size="middle" className="input-custom-md" />
                    </Form.Item>
                </Col>
            </Row>
        </FormPage>
    );
}
