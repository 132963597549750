import React, { Fragment, useState } from 'react';
import TablePage from '../../../../components/pages/TablePage';
import Page from '../../../../components/pages/Page';
import moment from 'moment';
import detail from "./detail";
import { Button } from 'antd';
import { UndoOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { PostAPI } from "../../../../redux";

export default ({ breadcrumb }) => {
    const dispatch = useDispatch();
    const [refresh, setRefresh] = useState(moment().unix());

    const columns = [
        {
            selector: (row) => row?.awb_number,
            name: "AWB Number",
            id: 'awb_number'
        },
        {
            selector: (row) => row?.flight_number,
            name: "Flight Number",
            id: 'flight_number'
        },
        {
            selector: (row) => row?.flight_date,
            name: "Flight Date",
            id: 'flight_date'
        },
        {
            selector: (row) => (row?.createdAt && moment(row.createdAt).format('DD-MMM-YYYY HH:mm')) || '-',
            name: "CreatedAt",
            id: 'created_at'
        },
    ]

    const handleVoid = (data) => {
        dispatch(PostAPI({ url: `api/v1/consolidation/void`, data })).then(resp => {
            if (resp.payload) {
                setRefresh(moment().unix());
            }
        });
    }

    return (
        <Fragment>
            <Page title='Consolidation' breadcrumb={breadcrumb} />
            <TablePage
                title={"Consolidation"}
                url="api/v1/consolidation"
                actionPosition={'first'}
                createPath="/consolidation/add"
                ExpandComponent={detail}
                columns={columns}
                refresh={refresh}
                otherAction={(row) => {
                    return (
                        <Fragment>
                            <Button
                                title="void"
                                icon={<UndoOutlined style={{ color: "red" }} />}
                                shape="circle"
                                type="link"
                                onClick={() => handleVoid(row)}
                            />
                        </Fragment>
                    )
                }}
            />
        </Fragment>
    )
}