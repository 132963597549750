import { Form, Input } from "antd";
import MyModal from '../../../../../components/pages/Modal';

export default ({ visible, handleCancel, handleVoid }) => {
    const [form] = Form.useForm();
    return (

        <MyModal
            title="Void"
            visible={visible}
            onOk={form.submit}
            handleCancel={() => {
                form.resetFields()
                handleCancel()
            }}
        >
            <div>
                <Form
                    name="basic"
                    form={form}
                    layout="vertical"
                    autoComplete="off"
                    size="large"
                    onFinish={(values) => {
                        console.log(values);
                        handleVoid(values)
                        form.resetFields()
                    }}
                >
                    <Form.Item label="Reason" name={"reason"} rules={[{ required: true, message: 'Please input !' }]}>
                        <Input.TextArea />
                    </Form.Item>
                </Form>
            </div>
        </MyModal>
    )
}