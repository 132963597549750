import React, { useEffect } from 'react';
import { SiderLayout } from '../components/layouts/sider';
import Menus from '../menus';
import { useDispatch, useSelector } from 'react-redux';
import { Routing } from '../routing';
import { Route } from 'react-router-dom';
import { Switch } from 'react-router-dom';
import { useLocHref } from '../../../hooks/useLocHref';
import { useMenu } from '../../../hooks/useMenu';
import { useNavs } from '../../../hooks/useNavs';
import { loadDestination, loadshipper } from '../../../redux/reducer/apps';
import PageNotfound from '../../../components/layouts/layout404';

const TheRouter = () => {
    const { userdata: { level } } = useSelector(state => state.auth);
    const routes = Routing.filter(r => (r.level & level) > 0);

    return (
        <Switch>
            {
                routes.map((route, idx) => (
                    <Route key={idx} path={route.to} exact={route.isExact !== false} render={props => {
                        return !!route.component && route.component
                    }} />
                ))
            }
            <Route path={'/*'}>
                <PageNotfound />
            </Route>
        </Switch>
    )
}

export default ({ idx }) => {
    // load data redux
    const { userdata } = useSelector(state => state.auth);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(loadDestination()) //load destination
        dispatch(loadshipper({ id: `${userdata?.agent_id}` })) //load agen      
    }, []);
    const { userdata: { level } } = useSelector(state => state.auth);
    const key = useLocHref('customer');
    const { keys, menus } = useMenu(Menus, level);
    useNavs(keys, key);
    return (
        <SiderLayout
            menus={menus}
            headerBgColor={"white"}
            headerColor={"black"}
            title={"Dashboard Customer"}
        >
            <TheRouter />
        </SiderLayout>
    )
}