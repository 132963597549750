import React from 'react';
import {
    RiHome2Line, RiCalendarCheckLine, RiUserStarLine, RiCalendarEventLine, RiCalendarTodoLine, RiExchangeDollarFill
} from 'react-icons/ri';
import { Link } from 'react-router-dom';
const SIZE = 20;
export default [
    {
        type: 'group', // Must have
        label: 'Dashboard',
        level: 0x1fff,
        // dashed: true,
        children: [
            {
                key: '/home',
                icon: <RiHome2Line size={SIZE} />,
                label: <Link to={'/home'}>Home</Link>,
                level: 0x1fff
            }
        ]
    },
    {
        type: 'group', // Must have
        label: 'Acceptance',
        level: 0x1fff,
        children: [
            {
                key: '/acceptence/booking',
                icon: <RiCalendarCheckLine size={SIZE} />,
                label: <Link to={'/acceptence/booking'}>Booking</Link>,
                level: 0x1fff
            },
            {
                key: '/acceptence/rate',
                icon: <RiExchangeDollarFill size={SIZE} />,
                label: <Link to={'/acceptence/rate'}>Simulation Rate</Link>,
                level: 0x1fff
            },
        ]
    },
    {
        type: 'group', // Must have
        label: 'Master',
        key: '/master/user_agent',
        level: 0x1fff,
        children: [
            {
                key: '/master/user_agent',
                icon: <RiUserStarLine size={SIZE} />,
                level: 0x1fff,
                label: <Link to={'/master/user_agent'}>Users</Link>,
            },
        ]
    },
    {
        type: 'group', // Must have
        label: 'Report',
        level: 0x1fff,
        children: [
            {
                key: '/report/daily',
                icon: <RiCalendarEventLine size={SIZE} />,
                label: <Link to={'/report/daily'}>Daily</Link>,
                level: 0x1fff
            },
            {
                key: '/report/monthly',
                icon: <RiCalendarTodoLine size={SIZE} />,
                label: <Link to={'/report/monthly'}>Monthly</Link>,
                level: 0x1fff
            },
        ]
    },
]