import { Col, Form, Input, Row, Switch } from 'antd';
import React, { useMemo, useState } from 'react';
import FormStep from '../../../../../../components/pages/FormStep';
import AutoComplete from "../../../../../../components/autocomplete/Offline";
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { PostAPI } from '../../../../../../redux';

const defVal = {
  shipper_name: "",
  shipper_addr: "",
  shipper_city: "",
  shipper_zip: "",
  shipper_contact: "",
  shipper_phone: "",
}

export default (props) => {
  const dispatch = useDispatch()
  const { destinationCache } = useSelector(state => state.apps)
  const { shipperCache } = useSelector(state => state.apps)
  const [data, setData] = useState({ ...defVal, ...props.model })
  const [form] = Form.useForm();
  const [tempZip, setTempZip] = useState([])

  //load postal code
  useMemo(() => {
    let splitCityShipper = data?.shipper_city?.name?.split(",")
    dispatch(PostAPI({ url: `/api/v1/postal_code/sub_district`, data: { sub_district: !!splitCityShipper && splitCityShipper[0] } }))
      .then(resp => setTempZip(resp.payload))
  }, [data?.shipper_city,])

  const handleChange = (checked) => {
    if (!checked) {
      const sorted = destinationCache.filter((el) => el.name === shipperCache.city);
      const [selectedCity] = sorted;
      form.setFieldsValue({
        shipper_name: shipperCache.name,
        shipper_addr: shipperCache.address,
        shipper_city: selectedCity,
        shipper_zip: shipperCache.postalCode,
        shipper_contact: shipperCache.name,
        shipper_phone: shipperCache.phone

      });
      setData({ ...data, shipper_city: selectedCity, shipper_zip: shipperCache.postalCode, })
    } else form.resetFields();

  }

  useMemo(() => {
    if (tempZip.length !== 0) {
      const sorted = tempZip.filter((el) => el.postal_code === props.model.shipper_zip);
      const [selectedZip] = sorted;
      console.log(selectedZip);
      form.setFieldsValue({
        shipper_zip: selectedZip
      })
      setData({ ...data, shipper_zip: selectedZip })
    }
  }, [tempZip])

  //reset field form
  useMemo(() => {
    if (!!props.model.shipper_city) form.setFieldsValue({ ...data, shipper_city: props.model.shipper_city })
  }, [props.model.shipper_city])

  return (
    <FormStep
      wrapperCol={{ span: 14 }}
      labelCol={{ span: 7 }}
      form={form}
      {...props}
      beforeSaveData={(body) => {
        const { shipper_city, shipper_zip } = body;
        const optZip = (data) => {
          if (typeof data.shipper_zip === "object") {
            return data.shipper_zip.postal_code
          } else {
            return data.shipper_zip
          }
        }
        return { ...body, shipper_city: data.shipper_city, hub_origin: shipperCache.originCode, shipper_zip: optZip(data) }
      }}
    >
      <Form.Item label="Options Address" >
        <Switch checkedChildren="Customer Address" unCheckedChildren="Agent Address" onChange={handleChange} defaultChecked />
      </Form.Item>
      <Row>
        <Col span={12}>
          <Form.Item name={"shipper_name"} label="Nama Pengirim" rules={[{ required: true, message: "'Nama Pengirim' wajib diisi" }]} >
            <Input autoFocus />
          </Form.Item>
          <Form.Item name={"shipper_addr"} label="Alamat Lengkap" rules={[{ required: true, message: "'Alamat Lengkap' wajib diisi" }]} >
            <Input.TextArea />
          </Form.Item>
          <Form.Item name={"shipper_city"} label="Kota dan Kecamatan" rules={[{ required: true, message: "'Kota dan Kecamatan' wajib diisi" }]} >
            <AutoComplete
              data={destinationCache}
              onChange={(val) => setData({ ...data, shipper_city: val })}
              value={data.shipper_city}
              getKey={(opt) => opt}
              getSelectedOptions={(opt, val) => opt.name === val.name}
              getValues={(opt) => `${opt.name}`}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={"shipper_zip"} label="Kode Pos" rules={[{ required: true, message: "'Kode Pos' wajib diisi" }]} tooltip="Untuk list cari berdasarkan nama kelurahan" >
            {tempZip.length === 0 ? <Input type={"number"} onChange={(e) => setData({ ...data, shipper_zip: e.target.value })} /> : (
              <AutoComplete
                data={tempZip}
                onChange={(val) => setData({ ...data, shipper_zip: val })}
                value={data.shipper_zip}
                getKey={(opt) => opt}
                getSelectedOptions={(opt, val) => opt._id === val._id}
                getValues={(opt) => `${opt.urban} - ${opt.postal_code}`}
              />
            )}
          </Form.Item>
          <Form.Item name={"shipper_contact"} label="Nama Kontak" rules={[{ required: true, message: "'Nama Kontak' wajib diisi" }]} >
            <Input />
          </Form.Item>
          <Form.Item name={"shipper_phone"} label="Nomor Hp" rules={[{ required: true, message: "'Nomor HP' wajib diisi" }]} >
            <Input type={"number"} />
          </Form.Item>
        </Col>
      </Row>
    </FormStep>
  )
}
