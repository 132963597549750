import React, { useState, Fragment } from 'react';
import { Space, Table, Col, Row, Button, Form, Input, DatePicker } from 'antd';
import ModalSelected from './modal_selected';
import Page from '../../../../components/pages/Page';
import { PostAPI } from "../../../../redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from 'moment';

const defaultValue = {
    "id_booking": [],
    "awb_number": "",
    "flight_number": "",
    "flight_date": "",
    "isCreate": true
}

export default ({ breadcrumb }) => {
    let history = useHistory();
    const [dataConsole, setDataConsole] = useState({ ...defaultValue })
    const dispatch = useDispatch();
    const [reload, setReload] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const dateFormat = 'DD MMM YYYY';

    const columns = [
        {
            title: 'Id Booking',
            dataIndex: 'btb_number',
            key: 'btb_number',
        },
        {
            title: 'HAWB Number',
            dataIndex: 'resi_number',
            key: 'resi_number',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (id, record, index) => (
                <Space size="middle">
                    <a onClick={() => {
                        const arr = dataConsole.id_booking
                        arr.splice(index, 1)
                        setDataConsole({ ...dataConsole, id_booking: arr })
                        setReload(true)
                        setTimeout(() => {
                            setReload(false)
                        }, 100);
                    }}>Delete</a>
                </Space>
            ),
        },
    ];

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleSave = (data) => {
        dispatch(PostAPI({ url: `api/v1/consolidation`, data })).then(resp => {
            if (resp.payload) {
                history.push("/consolidation");
            }
        });
    }

    return (
        <Fragment>
            <Page title='Consolidation' breadcrumb={breadcrumb} />
            <br />
            <Row>
                <Col span={12}>
                    <Button type="primary" onClick={() => showModal()}>Add</Button>
                    {reload === false ?
                        <Table columns={columns} dataSource={dataConsole?.id_booking} rowKey={(record) => record._id} /> : ""
                    }
                </Col>
                <Col span={11} style={{ marginLeft: 40 }}>
                    <Form
                        name="basic"
                        layout="vertical"
                        autoComplete="off"
                        size="large"
                        onFinish={(values) => {
                            handleSave({ ...dataConsole, awb_number: values.awb_number, flight_number: values.flight_number, flight_date: moment(values.flight_date).format('DD MMM YYYY') })
                        }}
                    >
                        <Row gutter={23} span={5}>
                            <Col className="gutter-row" style={{ marginTop: 40 }}>
                                <Row>
                                    <Space>
                                        <Form.Item
                                            label="AWB Number"
                                            name="awb_number"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input!',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            label="Flight Number"
                                            name="flight_number"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input!',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            label="Flight Date"
                                            name="flight_date"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input!',
                                                },
                                            ]}
                                        >
                                            <DatePicker format={dateFormat} />
                                        </Form.Item>
                                    </Space>
                                </Row>
                                <Form.Item >
                                    <Space>
                                        <Button type="primary" htmlType="submit">Save</Button>
                                        <Button type="primary" danger onClick={() => history.push("/consolidation")}>Close</Button>
                                    </Space>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Col>
                <ModalSelected isModalVisible={isModalVisible} handleCancel={handleCancel} setDataConsole={setDataConsole} dataConsole={dataConsole} />
            </Row>
        </Fragment>
    )
}