import React, { Fragment } from 'react';
import TablePage from '../../../../../components/pages/TablePage';
import Page from '../../../../../components/pages/Page';

export default ({ breadcrumb }) => {
    const COLUMNS = [
        {
            selector: (row) => row?.name,
            name: 'Name',
            wrap: true,
        },
        {
            selector: (row) => row.value,
            name: 'Value',
            wrap: true,
            format: ({ value, type, fieldVal }) => {
                switch (type) {
                    case 'string':
                        return value;
                    case 'object':
                        return value[`${fieldVal}`];
                    case 'array':
                        return value.join(', ');
                    case 'boolean':
                        return value.toString();
                    default:
                        return value;
                }
            }
        },
    ]
    return (
        <Fragment>
            <Page title='Config' breadcrumb={breadcrumb} />
            <TablePage
                url="api/v1/config"
                actionPosition={'first'}
                editPath={"/master/config/edit"}
                columns={COLUMNS}
            />
        </Fragment>
    )
}
