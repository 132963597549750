import { ArrowLeftOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Steps } from 'antd'
import React, { Fragment, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom';
import Page from './Page';
const { Step } = Steps;


export default ({ stepCompt, title, url, callbackPath, widthPreview }) => {

  const [current, setCurrent] = useState(0)
  const totStep=useMemo(()=> Array.isArray(stepCompt) && stepCompt.length-1 || 0, [stepCompt])

  return (
    <Page title={title}>
      <Steps progressDot current={current}>
        {!!stepCompt && (
          <Fragment>
            {stepCompt.map((item, idx) => (
              <Step key={idx} title={item.title} />
            ))}
          </Fragment>
        )}
      </Steps>
      <div>
        {!!stepCompt && (
          <Fragment>
            {stepCompt.map((item, idx) => (
              <div key={idx}>
                {idx === current && (<div >{item.compt({current, setCurrent, totStep, url, callbackPath, widthPreview, title})}</div>)}
              </div>
            ))}
          </Fragment>
        )}
        
      </div>
    </Page>
  )
}
