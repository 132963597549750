import React, { useState } from "react";
import { Form, Input, Row, Col } from "antd";
import FormPage from "../../../../../components/pages/FormPage";
import Page from '../../../../../components/pages/Page';

const DefModel = {
    courier_name: "",
    courier: "",
    url: "",
    username: "",
    password: "",
    airport_id: null,
    isCreate: true
};

export default ({ breadcrumb }) => {
    const [model, setModel] = useState(DefModel);
    return (
        <FormPage
            url={"api/v1/courier"}
            callbackPath={"/master/courier"}
            formValid={false}
            model={model}
            setModel={setModel}
            title={"Courier"}
        >
            <Page title='Expedisi' breadcrumb={breadcrumb} />
            <br />
            <Row gutter={24}>
                <Col span={8}>
                    <Form.Item name={"courier_name"} label="Expedisi Name" rules={[{ required: true }]} >
                        <Input autoFocus className="input-custom-md" />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item name={"courier"} label="Expedisi Code">
                        <Input className="input-custom-md" />
                    </Form.Item>
                </Col>
            </Row>
        </FormPage>
    );
}