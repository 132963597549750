import React, { Fragment } from 'react';
import { Link, useHistory } from "react-router-dom";

import '../layouts/layout.css';
import { Menu, Col, Row, Layout } from 'antd';
const { Header } = Layout;


function Navbar({ skeys }) {
    // const history = useHistory()

    return (
        <Fragment>
            <Header style={{ position: 'fixed', zIndex: 1, width: '100%', backgroundColor: 'white' }}>
                <Row style={{ justifyContent: 'space-between' }}>
                    <Col span={8}>
                        <img src="/img/others/klx_logo.png" alt="logo" style={{ width: 70, margin: 10 }} />
                    </Col>
                    <Col span={7}>
                        <Menu theme="white" mode="horizontal" defaultSelectedKeys={[`${skeys}`]}>
                            <Menu.Item key="0">
                                <Link to={`/Home`}>
                                    Home
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="1">
                                <Link to={`/tracking`}>
                                    Tracking
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="2">
                                <Link to={`/shipping_rate`}>
                                    Rate
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="3">
                                <Link to={`/about`}>
                                    About
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="4">
                                <Link to={'/customer/service'} style={{
                                    padding: '7px 16px',
                                    borderRadius: '20px',
                                    color: 'white',
                                    backgroundColor: '#3e79f7',
                                }}>
                                    Login
                                </Link>
                            </Menu.Item>
                        </Menu>
                    </Col>
                </Row>
            </Header >
        </Fragment >
    )
}

export default Navbar