import React, { Fragment } from 'react';
import TablePage from '../../../../../components/pages/TablePage';
import Page from '../../../../../components/pages/Page';
import moment from 'moment';

export default ({ breadcrumb }) => {
    const columns = [
        {
            selector: (row) => row?.agent_id.name,
            name: "agent",
            id: 'agent'
        },
        {
            selector: (row) => row?.name,
            name: "name",
            id: 'name'
        },
        {
            selector: (row) => row?.username,
            name: "username",
            id: 'username'
        },
        {
            selector: (row) => row?.email,
            name: "email",
            id: 'email'
        },
        {
            selector: (row) => row?.phone,
            name: "phone",
            id: 'phone'
        },
        {
            selector: (row) => (row?.createdAt && moment(row.createdAt).format('DD-MMM-YYYY HH:mm')) || '-',
            name: "CreatedAt",
            id: 'created_at'
        },
    ]
    return (
        <Fragment>
            <Page title='User Agent' breadcrumb={breadcrumb} />
            <TablePage
                title={"Master user agent"}
                url="api/v1/agent_users"
                actionPosition={'first'}
                createPath="/master/user_agent/create"
                columns={columns}
            />
        </Fragment>
    )
}