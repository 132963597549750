import React, { useMemo, useState } from 'react'
import { Layout, Menu, Typography, Col, Row } from 'antd';
import './layout.css';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedMenu, setOpenKeys } from '../../redux';
import { RiMenuFoldLine, RiMenuUnfoldLine } from 'react-icons/ri'
import { UserProfile } from './userProfile';
import { Scrollbars } from 'react-custom-scrollbars';
const { Header, Content, Footer, Sider } = Layout;

export const SiderLayout = ({ children, menus, title, theme, headerBgColor, headerColor }) => {
    const { selectedMenu, openKeys } = useSelector(state => state.nav);
    const { userdata } = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const [colapsed, setColapsed] = useState(false);

    const rootSubmenuKeys = useMemo(() => {
        return menus.map(({ key }) => key);
    }, [menus]);
    const renderThumb = ({ style }) => {
        return (<div style={{ ...style, backgroundColor: '#eecccc6e' }} />)
    }

    // console.log(menus)
    var result = menus.map(e => ({ ...e, label: '' }));

    return (
        <Layout hasSider>
            <Sider
                style={{ height: '100vh', position: 'fixed', paddingBottom: 90  }}
                breakpoint="lg"
                theme={theme || 'light'}
                collapsed={colapsed}
            >
                <div className='logo'>
                    {colapsed === false ?
                        <img src='/img/others/klx_logo.png' alt="Logo" style={{ width: 80, margin: 10 }} /> :
                        <img src='/img/others/klx_logo.png' alt="Logo" style={{ width: 60, margin: 10 }} />
                    }
                </div>
                <Scrollbars
                    renderThumbVertical={renderThumb}
                >
                    <Menu
                        mode="inline"
                        selectedKeys={[selectedMenu]}
                        theme={'light'}
                        items={colapsed === false ? menus || [] : result || []}
                        onClick={e => {
                            dispatch(setSelectedMenu(e.key));
                        }}
                        openKeys={openKeys}
                        onOpenChange={keys => {
                            const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
                            if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
                                dispatch(setOpenKeys(keys));
                            } else {
                                dispatch(setOpenKeys(latestOpenKey ? [latestOpenKey] : []));
                            }
                        }}
                    />
                </Scrollbars>
            </Sider>
            <Layout style={{ marginLeft: colapsed ? 80 : 200, minHeight: '100vh' }}>
                <Header className="site-layout-sub-header-background" style={{ boxShadow: '0 1px 4px -1px rgba(0,0,0,.15)', backgroundColor: headerBgColor, width: '100%', zIndex: 99, position: 'fixed', color: headerColor, display: 'flex', flexDirection: 'row' }}>
                    {
                        colapsed ? <RiMenuFoldLine size={20} className='trigger' onClick={() => setColapsed(!colapsed)} /> : <RiMenuUnfoldLine size={20} className='trigger' onClick={() => setColapsed(!colapsed)} />
                    }
                    <Typography.Title level={4} style={{ color: headerColor, fontSize: 17 }}>
                        {title || 'Dashboard'}
                    </Typography.Title>
                    <UserProfile isColapsed={colapsed} username={userdata?.username} level={userdata?.levelStr} />
                </Header>
                <Scrollbars
                    renderThumbVertical={renderThumb}
                >
                    <Content style={{ margin: '80px 16px 0', display: 'flex', flexDirection: 'column', minHeight: `calc(100% - 80px)` }}>
                        <div className="site-layout-background" style={{ padding: 24, flexGrow: 1 }}>
                            {children}
                        </div>
                    </Content>
                </Scrollbars>
                <Footer style={{ textAlign: 'center', height: 64 }}>
                    <Row>
                        <Col span={10}>COPYRIGHT © 2022 <a target={'_blank'} href="https://edifly-si.com/">Edifly Solusi Indonesia</a>, All rights Reserved</Col>
                        <Col span={6} offset={8}>
                            {/* <span>Supported By</span> */}
                            <img src="/img/others/garuda.png" alt="logo" style={{ width: 60, marginTop: -12, marginLeft: 20 }} />
                            <img src="/img/others/kirimaja.png" alt="logo" style={{ width: 60, marginTop: -12, marginLeft: 20 }} />
                        </Col>
                    </Row>
                </Footer>
            </Layout>
        </Layout>
    )
}