import React from 'react'
import { RiInboxFill } from 'react-icons/ri'
import './emptPageStyle.css'

export default () => {
  return (
    <div className='wrapper' >
      <RiInboxFill size="3rem" />
      <h5 className='title-wrap'>NO RESULT FOUND</h5>
    </div>
  )
}
