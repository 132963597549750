import React, { useCallback } from 'react';
import { useDispatch } from "react-redux";
import { Button, Form, Input } from "antd";
import { MailOutlined, LockOutlined } from '@ant-design/icons';
import { login } from '../../redux/reducer/auth';

export const LoginForm = () => {
    const dispatch = useDispatch();
    const onLogin = useCallback((values) => {
        dispatch(login({ ...values, app: 'klx-office' }));
    }, []);

    return (
        <>
            <Form
                layout="vertical"
                name="login-form"
                onFinish={onLogin}
            >
                <Form.Item
                    name="user"
                    label="Username"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your user',
                        }
                    ]}>
                    <Input prefix={<MailOutlined className="text-primary" />} />
                </Form.Item>
                <Form.Item
                    name="password"
                    label='Password'
                    rules={[
                        {
                            required: true,
                            message: 'Please input your password',
                        }
                    ]}
                >
                    <Input.Password prefix={<LockOutlined className="text-primary" />} />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" block>
                        Sign In
                    </Button>
                </Form.Item>
            </Form>
        </>
    )
}

export default LoginForm;
