import { Button, Form, Modal } from "antd";
import React, { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { PostAPI } from "../../redux";
import { sendNotification } from "../../redux/reducer/utils";

export default ({
  model,
  setModel,
  children,
  totStep,
  labelCol,
  wrapperCol,
  formLayout,
  current,
  setCurrent,
  beforeSaveData,
  validationValue,
  url,
  callbackPath,
  form,
  compPreview,
  widthPreview,
  title
}) => {
  const [openPrivew, setOpenPrivew] = useState(false);

  const isFinish = useMemo(() => current >= totStep, [current, totStep]);
  const handleShow = () => setOpenPrivew(!openPrivew);
  const handleSubmit = async (values) => {
    const newModel = { ...model, ...values };
    const data =
      (!!beforeSaveData &&
        typeof beforeSaveData === "function" &&
        beforeSaveData(newModel)) ||
      newModel;
    setModel(data);
    if (!isFinish) {
      setCurrent(current + 1);
    } else {
      const isValid = typeof validationValue === "function" && validationValue({ data })
      if (!!isValid) setOpenPrivew(!openPrivew)
    }
  };
  return (
    <div style={{ display: "flex", flexDirection: "column", padding: "50px" }}>
      <Form
        autoComplete={"off"}
        layout={formLayout || "vertical"}
        labelCol={labelCol}
        wrapperCol={wrapperCol}
        initialValues={{ ...model }}
        onFinish={handleSubmit}
        form={form}
      >
        {children}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            marginBlock: 16,
          }}
        >
          {current > 0 && (
            <Button
              size="middle"
              className="btn-secondary"
              style={{ marginInline: 16, backgroundColor: "rgba(153, 153, 153, 1)", borderColor: "rgba(153, 153, 153, 1)" }}
              type="primary"
              onClick={() => setCurrent((curr) => curr - 1)}
            >
              Back
            </Button>
          )}
          {!isFinish ? (
            <Button size="middle" type="primary" htmlType="submit">
              Next
            </Button>
          ) : (
            <Button size="middle" type="primary" htmlType="submit">
              Finish
            </Button>
          )}
        </div>
      </Form>
      <ModalPreview validationValue={validationValue} openPrivew={openPrivew} handleShow={handleShow} title={title} callbackPath={callbackPath} url={url} model={model} compPreview={compPreview} widthPreview={widthPreview} />
    </div>
  );
};

export const ModalPreview = ({ openPrivew, handleShow, model, callbackPath, url, compPreview, widthPreview, title, validationValue }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const handleOk = async () => {
    const resp = await dispatch(PostAPI({ url, data: model, callbackPath }));
    if (!!resp?.payload) {
      if (!!callbackPath) {
        history.push({ pathname: callbackPath });
        sendNotification("success", "Success Save")
      }
    }
  }
  return (
    <Modal
      title={`${title} Priview`}
      visible={openPrivew}
      onOk={handleOk}
      onCancel={handleShow}
      width={widthPreview || "70%"}
      style={{ top: 20 }}
    >
      {typeof compPreview === "function" && compPreview({ model })}
    </Modal>
  );
};
