import * as Apps from './reducer/apps'
import * as Auth from './reducer/auth'
import * as Nav from './reducer/navigations'
import * as Api from './reducer/apiHandling'

export const { doLogout, initComplete, initMe, login, logout, setApps, successLogin } = Auth;
export const { loadshipper, seshipper, setLoading, unsetLoading, setDestination,
    loadAgent, setAgent,
    loadKotaCode, setKotaCode } = Apps;
export const { setOpenKeys, setSelectedMenu, setSelectedMenuKeys } = Nav;
export const { GetAPI, PostAPI } = Api;