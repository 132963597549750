import React from 'react'
import { Avatar, Dropdown, Menu, Typography } from 'antd'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { logout } from '../../redux'
import { UserOutlined } from '@ant-design/icons'
import { RiLogoutBoxRLine, RiUser6Line } from 'react-icons/ri'

export const UserProfile = ({ username, level, isColapsed }) => {
    const dispatch = useDispatch();

    const OverlayMenu = () => {
        return <Menu items={[
            {
                label: <Link onClick={() => dispatch(logout())} >Logout</Link>,
                key: 'Logout',
                icon: <RiLogoutBoxRLine />,
            },
        ]} />
    }
    return (
        <div className='user-profile-wrapper' style={{ marginRight: isColapsed ? 128 : 248 }}>
            <div className='user-profile-text-wrapper'>
                <Typography.Text strong type='success' className='user-profile-text'>
                    {username}
                </Typography.Text>
            </div>
            <Dropdown placement='bottomLeft' overlay={OverlayMenu} trigger={['click']}>
                <Avatar icon={<UserOutlined />} src="https://joeschmoe.io/api/v1/random" style={{ backgroundColor: '#3AB4F2', cursor: 'pointer' }} />
            </Dropdown>
        </div>
    )
}