// import { Divider, Typography } from 'antd'
// import React, { Fragment, useEffect } from 'react'
// import CONFIG from '../../config/env';

// const { Title } = CONFIG;

// export default ({ title, children }) => {
//     useEffect(() => {
//         document.title = `${Title} (${title})`;
//     }, [])

//     return (
//         <Fragment>
//             <Typography.Title level={4}>{title}</Typography.Title>
//             <Divider style={{ marginBlock: 16 }} />
//             <div style={{ display: 'flex', flexDirection: 'column' }}>
//                 {children}
//             </div>
//         </Fragment>
//     )
// }

import { Breadcrumb } from 'antd'
import React, { useEffect, Fragment } from 'react'
import CONFIG from '../../config/env';
const { Title } = CONFIG;

export default ({ title, breadcrumb, children }) => {
    useEffect(() => {
        document.title = `${Title} | ${title} `;
    }, [])

    return (
        <Fragment>
            <Breadcrumb>
                {!!breadcrumb && Array.isArray(breadcrumb) && breadcrumb.map((logs, idx) => (
                    <Breadcrumb.Item key={idx}>{logs}</Breadcrumb.Item>
                ))
                }
            </Breadcrumb>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {children}
            </div>
        </Fragment>
    )
}