import { UserOutlined } from '@ant-design/icons';
import { Avatar, Col, Row } from 'antd';
import { RiMapPin2Fill } from 'react-icons/ri';
import React from 'react';

export default () => {
  return (
    <div>
      <Row>
        <Col span={3}>
          <Avatar icon={<UserOutlined />} src="https://joeschmoe.io/api/v1/random" style={{ backgroundColor: '#3AB4F2', cursor: 'pointer', width: "5rem", height: "5rem" }} />
        </Col>
        <Col span={21}>
          <h3>Admin</h3>
          <div style={{ color: "#999" }}>
            <RiMapPin2Fill />
            <span style={{ marginLeft: "8px" }}>Kuta, Badung, Bali - 80361</span>
          </div>
        </Col>
      </Row>
    </div>
  )
}
