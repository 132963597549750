import React from 'react';
import MyModal from '../../../../../components/pages/Modal';
import { Col, Row, Form, Select } from "antd";
const { Option } = Select;

export default ({ data, visible, handleCancel, handleOpenDetail }) => {
    const [form] = Form.useForm();
    return (
        <MyModal
            title="Selected Courier"
            visible={visible}
            onOk={form.submit}
            handleCancel={handleCancel}
        >
            <div>
                <Form
                    name="basic"
                    form={form}
                    layout="vertical"
                    autoComplete="off"
                    size="large"
                    onFinish={(values) => {
                        const { courier, service } = values;
                        handleOpenDetail({ ...data, courier, service })
                        handleCancel()
                    }}
                >
                    <Row gutter={23} span={5}>
                        <Col className="gutter-row" span={12}>
                            <Form.Item
                                label="courier"
                                name="courier"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input!',
                                    },
                                ]}
                            >
                                <Select
                                    style={{
                                        width: 180,
                                    }}
                                >
                                    <Option value="ajc">ajc</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={12}>
                            <Form.Item
                                label="service"
                                name="service"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input!',
                                    },
                                ]}
                            >
                                <Select
                                    style={{
                                        width: 200,
                                    }}
                                >
                                    <Option value="SLV">SLV</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        </MyModal>
    )
}