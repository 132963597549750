import React from 'react';
import { Layout } from 'antd';
import Navbar from '../../components/layouts/Navbar';
import { Helmet } from "react-helmet";
import TrackingForm from './tracking-form';
import './landing.css';

function Tracking() {
    const kId = 1;

    return (
        <div>
            <Layout>
                <Helmet>
                    <title>KLX | Tracking</title>
                </Helmet>
                <Navbar skeys={kId} />
                <div className='container-tracking'>
                    <TrackingForm />
                </div>
            </Layout>
        </div>
    )
}

export default Tracking