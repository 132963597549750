import { Form, Input, Row, Col, Select } from "antd";
import React, { useState } from "react";
import FormPage from "../../../../../components/pages/FormPage";
import Page from '../../../../../components/pages/Page';
import { useSelector } from 'react-redux'
const { Option } = Select;

const DefModel = {
    agent_id: "",
    name: "",
    username: "",
    password: "",
    email: "",
    phone: "",
    level: 1,
    isCreate: true
};

export default ({ breadcrumb }) => {
    const [model, setModel] = useState(DefModel);
    const { agentCache } = useSelector(state => state.apps)

    return (
        <FormPage
            url={"api/v1/agent_users"}
            callbackPath={"/master/user_agent"}
            formValid={false}
            model={model}
            setModel={setModel}
            title={"Users Agent"}
        >
            <Page title='User Agent' breadcrumb={breadcrumb} />
            <br />
            <Row gutter={24}>
                <Col span={8}>
                    <Form.Item name={"agent_id"} label="agent" rules={[{ required: true }]} >
                        <Select
                            showSearch
                            listItemHeight={3}
                            listHeight={125}
                            style={{ width: '100%' }}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {agentCache.map((kab, index) => (
                                <Option value={kab._id}>{kab.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item name={"name"} label="name">
                        <Input className="input-custom-md" size="default" />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item name={"username"} label="username" rules={[{ required: true }]}>
                        <Input className="input-custom-md" size="default" />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item name={"password"} label="password">
                        <Input className="input-custom-md" size="default" />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item name={"email"} label="email" rules={[{ required: true }]}>
                        <Input className="input-custom-md" size="default" />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item name={"phone"} label="phone" rules={[{ required: true }]}>
                        <Input className="input-custom-md" size="default" />
                    </Form.Item>
                </Col>
            </Row>
        </FormPage>
    );
}