import React, { Fragment } from 'react';
import { Modal, Button } from 'antd';
import TablePage from '../../../../components/pages/TablePage';
import moment from 'moment';
import { PlusSquareTwoTone, MinusSquareOutlined } from "@ant-design/icons";
import { blue, red } from "@ant-design/colors";

export default ({ isModalVisible, handleCancel, setDataConsole, dataConsole }) => {

    const columns = [
        {
            selector: (row) => row?.resi_number || "-",
            name: "HAWB",
            id: 'resi_number'
        },
        {
            selector: (row) => row?.courier || "-",
            name: "Expedisi",
            id: 'courier'
        },
        {
            selector: (row) => row?.btb_number,
            name: "Id Booking",
            id: 'btb_number'
        },
        {
            selector: (row) => row?.shipper_name,
            name: "Shipper Name",
            id: 'shipper_name'
        },
        {
            selector: (row) => row?.shipper_addr,
            name: "Shipper Addr",
            id: 'shipper_addr'
        },
        {
            selector: (row) => row?.shipper_phone,
            name: "Shipper Phone",
            id: 'shipper_phone'
        },
        {
            selector: (row) => row?.receiver_name,
            name: "Receiver Name",
            id: 'receiver_name'
        },
        {
            selector: (row) => row?.receiver_addr,
            name: "Receiver Addr",
            id: 'receiver_addr'
        },
        {
            selector: (row) => row?.receiver_phone,
            name: "Receiver Phone",
            id: 'receiver_phone'
        },
        {
            selector: (row) => `${row?.pieces} pcs`,
            name: "Pieces",
            id: 'pieces'
        },
        {
            selector: (row) => `${row?.weight} kg`,
            name: "Weight",
            id: 'weight'
        },
        {
            selector: (row) => `Rp. ${(row?.createdAt && moment(row.createdAt).format('DD MMM YYYY HH:mm'))}` || '-',
            name: "CreatedAt",
            id: 'createdAt'
        },
    ]

    return (
        <Fragment>
            <Modal title="Selected Delivery" visible={isModalVisible} onCancel={handleCancel} width={'70%'} footer={null}>
                <TablePage
                    url="api/v1/booking/consolidation"
                    actionPosition={'first'}
                    columns={columns}
                    otherAction={(row) => {
                        const btbNumber = dataConsole?.id_booking.map((item) => item?.btb_number);
                        const btnDisabled = btbNumber.includes(row?.btb_number);
                        return (
                            <Fragment>
                                {btnDisabled ? (
                                    <Button
                                        title="selected"
                                        disabled
                                        icon={<MinusSquareOutlined color={red[4]} />}
                                        shape="circle"
                                        type="link"
                                    />
                                ) : (
                                    <Button
                                        title="selected"
                                        icon={<PlusSquareTwoTone color={blue[4]} />}
                                        shape="circle"
                                        type="link"
                                        onClick={() => {
                                            setDataConsole({ ...dataConsole, id_booking: [...dataConsole.id_booking, row] })
                                        }}
                                    />
                                )}
                            </Fragment>
                        )
                    }}
                />
            </Modal>
        </Fragment>
    )
}