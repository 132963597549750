import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { GetAPI, PostAPI, GetAPIMe } from './apiHandling'
import jwtDecode from 'jwt-decode'
import { levelToRole } from './levelConvert'
import { sendNotification } from "./utils";

const authSlice = createSlice({
    name: 'auth',
    initialState: { userdata: false, token: '', apps: '', initComplete: false },
    reducers: {
        successLogin(state, action) {
            const { userdata } = action.payload;
            const levelStr = levelToRole(userdata.level || 0);
            state.userdata = { ...userdata, levelStr };
            state.token = action.payload.token
        },
        setApps(state, action) {
            state.apps = action.payload
        },
        doLogout(state) {
            state.userdata = false
            state.token = ''
        },
        initComplete(state) {
            state.initComplete = true
        }
    }
})

export const { successLogin, setApps, doLogout, initComplete } = authSlice.actions

export const login = createAsyncThunk('auth/login', async ({ user, password, app }, thunkAPI) => {
    const { dispatch, getState } = thunkAPI
    const { auth: { apps } } = getState()
    const tokenName = `${apps}_token`
    const token = await dispatch(PostAPI({ url: 'auth/login', data: { username: user, password, app } }))
    if (!!token.payload) {
        const userdata = jwtDecode(token.payload)
        window.localStorage.setItem(tokenName, token.payload)
        dispatch(successLogin({ token: token.payload, userdata }))
        sendNotification("success", 'Welcome To KLX');
    }
})
export const logout = createAsyncThunk('auth/logout', async (body, thunkAPI) => {
    const { dispatch, getState } = thunkAPI
    const { auth: { apps } } = getState()
    await dispatch(GetAPIMe({ url: 'auth/logout' }))
    const tokenName = `${apps}_token`
    window.localStorage.removeItem(tokenName)
    dispatch(doLogout())
})
export const initMe = createAsyncThunk('auth/me', async (body, thunkAPI) => {
    //dipslay masih splash
    const { dispatch, getState } = thunkAPI
    const { auth: { apps } } = getState()
    const tokenName = `${apps}_token`
    const token = window.localStorage.getItem(tokenName)
    if (!!token) {
        const userdata = jwtDecode(token)
        dispatch(successLogin({ userdata, token }))
        dispatch(GetAPIMe({ url: 'auth/me' }))
        if (userdata) dispatch(successLogin({ userdata, token }))
    }
    dispatch(initComplete())
})
// export const login
export default authSlice.reducer