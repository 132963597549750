import React, { Fragment } from "react";
import TablePage from '../../../../components/pages/TablePage';
import Page from '../../../../components/pages/Page';
import numeral from "numeral";
import moment from 'moment';

export default ({ breadcrumb }) => {

    const columns = [
        {
            selector: (row) => row?.reason || "-",
            name: "Reason",
            id: 'reason'
        },
        {
            selector: (row) => row?.awb_number || "-",
            name: "AWB",
            id: 'awb_number'
        },
        {
            selector: (row) => row?.resi_number || "-",
            name: "HAWB",
            id: 'resi_number'
        },
        {
            selector: (row) => row?.agent.name,
            name: "Agent Booking",
            width: 200,
            id: 'agent'
        },
        {
            selector: (row) => row?.courier || "-",
            name: "Expedisi",
            id: 'courier'
        },
        {
            selector: (row) => row?.btb_number,
            name: "Resi Number",
            id: 'btb_number'
        },
        {
            selector: (row) => row?.shipper_name,
            name: "Shipper Name",
            id: 'shipper_name'
        },
        {
            selector: (row) => row?.shipper_addr,
            name: "Shipper Addr",
            id: 'shipper_addr'
        },
        {
            selector: (row) => row?.shipper_phone,
            name: "Shipper Phone",
            id: 'shipper_phone'
        },
        {
            selector: (row) => row?.receiver_name,
            name: "Receiver Name",
            id: 'receiver_name'
        },
        {
            selector: (row) => row?.receiver_addr,
            name: "Receiver Addr",
            id: 'receiver_addr'
        },
        {
            selector: (row) => row?.receiver_phone,
            name: "Receiver Phone",
            id: 'receiver_phone'
        },
        {
            selector: (row) => `${row?.pieces} pcs`,
            name: "Pieces",
            id: 'pieces'
        },
        {
            selector: (row) => `${row?.weight} kg`,
            name: "Weight",
            id: 'weight'
        },
        {
            selector: (row) => `${row?.charged_weight} kg`,
            name: "Charges Weight",
            id: 'charged_weight'
        },
        {
            selector: (row) => `Rp. ${numeral(row?.price_agent).format("0,0.00")}`,
            name: "Price Agent",
            id: 'price_agent'
        },
        {
            selector: (row) => `Rp. ${numeral(row?.price_klx).format("0,0.00")}`,
            name: "Price KLX",
            id: 'price_klx'
        },
        {
            selector: (row) => `Rp. ${numeral(row?.price_courier).format("0,0.00")}` || "-",
            name: "Price Expedisi",
            id: 'price_courier'
        },
        {
            selector: (row) => `Rp. ${numeral(row?.profit_klx).format("0,0.00")}` || "-",
            name: "Profit KLX",
            id: 'profit_klx'
        },
        {
            selector: (row) => `${(row?.createdAt && moment(row.createdAt).format('DD MMM YYYY HH:mm'))}` || '-',
            name: "CreatedAt",
            id: 'createdAt'
        },
    ]

    return (
        <Fragment>
            <Page title='Void List' breadcrumb={breadcrumb} />
            <TablePage
                url="api/v1/booking/void"
                actionPosition={'first'}
                columns={columns}
            />
        </Fragment>
    )
}