import { Form, Input, Row, Col } from "antd";
import React, { useState } from "react";
import FormPage from "../../../../../components/pages/FormPage";
import { useSelector } from 'react-redux';

export default () => {
    const { userdata } = useSelector(state => state.auth);
    const DefModel = {
        agent_id: userdata.agent_id,
        name: "",
        username: "",
        password: "",
        email: "",
        phone: "",
        level: 1,
        isCreate: true
    };

    const [model, setModel] = useState(DefModel);

    return (
        <FormPage
            url={"api/v1/agent_users"}
            callbackPath={"/master/user_agent"}
            formValid={false}
            model={model}
            setModel={setModel}
            title={"Users Agent"}
            wrapperCol={{ span: 14 }}
            labelCol={{ span: 7 }}
        >
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item name={"name"} label="name" rules={[{ required: true }]}>
                        <Input className="input-custom-md" />
                    </Form.Item>
                    <Form.Item name={"username"} label="username" rules={[{ required: true }]}>
                        <Input className="input-custom-md" />
                    </Form.Item>
                    <Form.Item name={"password"} label="password" rules={[{ required: true }]}>
                        <Input className="input-custom-md" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name={"email"} label="email" rules={[{ required: true }]}>
                        <Input className="input-custom-md" />
                    </Form.Item>
                    <Form.Item name={"phone"} label="phone" rules={[{ required: true }]}>
                        <Input className="input-custom-md" />
                    </Form.Item>
                </Col>
                <Col span={6}>
                </Col>
                <Col span={6}>
                </Col>
                <Col span={6}>
                </Col>
            </Row>
        </FormPage>
    );
}