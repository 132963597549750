import React from 'react';

export default ({ data }) => {
    const { id_booking } = data
    return (
        <div style={{ paddingInline: 16, paddingBlock: 8 }}>
            <table className='table-0'>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>House Number</th>
                        <th>Resi Number</th>
                        <th>Shipper Name</th>
                        <th>Shipper Addr</th>
                        <th>Receiver Name</th>
                        <th>Receiver Addr</th>
                        <th>Goods Desc</th>
                        <th>pieces</th>
                        <th>Weight</th>
                        <th>Volume</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        (!!id_booking && Array.isArray(id_booking) && id_booking.length > 0 && id_booking.map((row, idx) => {
                            const { btb_number, resi_number, shipper_name, shipper_city, receiver_name, receiver_city, goods_desc, pieces, charged_weight, volume } = row;
                            return (
                                <tr key={idx}>
                                    <td style={{ textAlign: 'center' }}>{idx + 1}</td>
                                    <td style={{ textAlign: 'center' }}>{btb_number}</td>
                                    <td style={{ textAlign: 'center' }}>{resi_number}</td>
                                    <td style={{ textAlign: 'center' }}>{shipper_name}</td>
                                    <td style={{ textAlign: 'center' }}>{shipper_city?.name}</td>
                                    <td style={{ textAlign: 'center' }}>{receiver_name}</td>
                                    <td style={{ textAlign: 'center' }}>{receiver_city?.name}</td>
                                    <td style={{ textAlign: 'center' }}>{goods_desc}</td>
                                    <td style={{ textAlign: 'center' }}>{pieces}</td>
                                    <td style={{ textAlign: 'center' }}>{charged_weight}</td>
                                    <td style={{ textAlign: 'center' }}>{volume}</td>
                                </tr>
                            )
                        }))
                    }
                </tbody>
            </table>
        </div>
    )
}