import { Fragment } from 'react';
import MainRouter from './content/MainRouter';
import LoadingOverlay from 'react-loading-overlay';
import { useSelector } from 'react-redux';
import './assets/css/style.css'

LoadingOverlay.propTypes = undefined;

function App() {
  const { loading } = useSelector(state => state.apps);

  return (
    <Fragment>
      <LoadingOverlay
        active={loading}
        spinner
        text="Loading"
      >
        <MainRouter />
      </LoadingOverlay>
    </Fragment>
  );
}

export default App;
