import Axios from "axios";
import moment from 'moment';
import numeral from "numeral";
import Config from '../../config/env'
const { ContainerPrinter } = Config

export const Print_label = async (row) => {
    const { btb_number, charged_weight, resi_number, shipper_contact, shipper_addr, shipper_city, shipper_name, shipper_phone, shipper_zip,
        receiver_addr, receiver_city, receiver_contact, receiver_name,receiver_phone, receiver_zip,goods_desc, pieces
    } = row;

    const data=[];
    for (let i = 0; i < pieces; i++) {
        data.push({num:`${i+1}`, total:`${pieces}`});
    }

    const datasave = {
        selectedPrinter: `printerlabel`,
        master: {
            btb_number,
            charged_weight:numeral(charged_weight).format('0,000'),
            resi_number:`${resi_number}`,
            shipper_addr,
            shipper_contact,
            shipper_city:shipper_city.name,
            shipper_name,
            shipper_phone,
            shipper_zip,
            receiver_addr,
            receiver_city:receiver_city.name,
            receiver_contact,
            receiver_name,
            receiver_phone,
            receiver_zip,
            goods_desc

        },
        detail: {
            data
        }
    }

    try {

        const config = {
            headers: {
                "Content-type": "application/json"
            }
        }

        const body = JSON.stringify({ ...datasave })

        await Axios.post(ContainerPrinter + 'printer/print', body, config)

    } catch (error) {
        alert("Harap periksa koneksi server printer")
    }
}