const BaseUrl = '/service/';
const ImagesUrl = '/images/';
const Title = 'KLX';
const Url = 'https://klx.co.id';
const ContainerPrinter = 'http://localhost:24813/';
export default {
    BaseUrl,
    ImagesUrl,
    Title,
    ContainerPrinter,
    Url
}